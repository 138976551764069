import type { ApiErrorResponse } from './types';
import { useCriticalError } from '@/components/CriticalErrorModal/useCriticalError';
import { Headers } from '@/constants';
import { useApp, useNotifications } from '@/composables';
import { getAccessToken } from '@/utils/accessToken';
import router from '@/router';
import { useRbac } from '@/plugins';

export const isApiErrorResponse = (
    error: ApiErrorResponse | unknown
): error is ApiErrorResponse => {
    return (
        !!error &&
        typeof error === 'object' &&
        'message' in error &&
        typeof error.message === 'string'
    );
};

export const handleError = async (
    {
        statusCode,
        message,
        requestId,
    }: {
        statusCode?: number;
        message?: string;
        requestId?: string;
    },
    options: { showToastOnError?: boolean; logoutOnUnauthorized?: boolean } = {
        showToastOnError: true,
        logoutOnUnauthorized: true,
    }
): Promise<void> => {
    const notifications = useNotifications();
    const criticalError = useCriticalError();

    const mergedOptions = {
        showToastOnError: true,
        logoutOnUnauthorized: true,
        ...options,
    };

    if (statusCode && statusCode >= 500 && statusCode < 599) {
        criticalError.push({
            message,
            requestId: requestId ?? 'Missing request id',
        });

        return;
    }

    const status = statusCode ? ` [Error: ${statusCode}]` : '';

    if (options.showToastOnError) {
        notifications.add({
            message: message ? message : `Something went wrong with the request. ${status}`,
            type: 'danger',
        });
    }

    if (statusCode && statusCode === 401 && mergedOptions.logoutOnUnauthorized) {
        router.push({ name: 'logout' });
    }
};

export const getDefaultHeaders = (overrides: Record<string, string | null> = {}) => {
    const app = useApp();

    const headers: Record<string, string> = {
        [Headers.CONTENT_TYPE]: 'application/json',
        [Headers.AUTHORIZATION]: `Bearer ${getAccessToken() ?? 'MISSING_ACCESS_TOKEN'}`,
        [Headers.X_PLATFORM_ID]: app.platformId.value ?? '',
    };

    if (overrides) {
        Object.entries(overrides).forEach(([key, value]) => {
            if (value === null) {
                delete headers[key];
            } else {
                headers[key] = value;
            }
        });
    }

    return headers;
};

export const getErrorTrackingParams = (error: ApiErrorResponse | unknown) => {
    return isApiErrorResponse(error) && error.statusCode === 403
        ? {
              privileges: useRbac().privileges.value,
          }
        : undefined;
};
