import type {
    Invoice,
    OneOffInvoiceLine,
    Payment,
    PricingPlanSubscription,
    WithCustomFieldsPayload,
} from '@solvimon/types';
import { downloadFile } from '@solvimon/ui';
import type { InvoiceCredit, OneOffInvoice, OrderByOption } from './invoices.types';
import { handleResponse, getFormattedDateRangeFilter } from './lib';
import { get, patch, post } from '@/callcenter';
import type { OrderDirection } from '@/types';
import { withoutEmptyProperties } from '@/utils/withoutEmptyProperties';
import { Headers } from '@/constants';
import { serializeQueryParams, withPagination } from '@/utils/api';

const ENDPOINT = `${import.meta.env.TRANSACTION_URL}/invoices`;

const PAGE_SIZE = 15;

export const getInvoice = (id: string) => {
    return handleResponse<Invoice>({
        request: get(`${ENDPOINT}/${id}`),
    });
};

export const getInvoices = async ({
    page,
    orderBy,
    orderDirection,
    status,
    customerId,
    invoiceDateFrom,
    invoiceDateTo,
    pageSize,
    pricingPlanSubscriptionId,
    paid,
    onHold,
}: {
    page: number;
    orderBy?: OrderByOption;
    orderDirection?: OrderDirection;
    status?: string[];
    customerId?: string;
    invoiceDateFrom?: string;
    invoiceDateTo?: string;
    pageSize?: number;
    pricingPlanSubscriptionId?: PricingPlanSubscription['id'];
    paid?: string;
    onHold?: string;
}) => {
    const queryParams = withPagination(
        {
            statuses: status ?? null,
            customer_id: customerId ?? null,
            pricing_plan_subscription_id: pricingPlanSubscriptionId ?? null,
            paid: paid ?? null,
            on_hold: onHold ?? null,
        },
        {
            page,
            pageSize: pageSize ?? PAGE_SIZE,
            orderBy,
            orderDirection,
        }
    );

    if (invoiceDateFrom || invoiceDateTo) {
        Object.assign(queryParams, {
            invoice_date: getFormattedDateRangeFilter(invoiceDateFrom, invoiceDateTo),
        });
    }

    return handleResponse<Invoice>({
        request: get(`${ENDPOINT}${serializeQueryParams(queryParams)}`),
        isCollection: true,
    });
};

export const refreshEInvoice = (invoiceId: string) => {
    return handleResponse<Invoice>({
        request: post(`${ENDPOINT}/${invoiceId}/refresh-e-invoices`),
    });
};

export const resubmitEInvoice = (invoiceId: string, mandate: string) => {
    return handleResponse<Invoice>({
        request: post(`${ENDPOINT}/${invoiceId}/resubmit-e-invoice`, { mandate }),
    });
};

export const getEInvoiceFile = async (invoiceId: string, externalReference: string) => {
    const queryParams = {
        external_reference: `${externalReference}`,
        file_format: 'UBL_XML',
    };

    await get(`${ENDPOINT}/${invoiceId}/download-e-invoice${serializeQueryParams(queryParams)}`, {
        headers: { [Headers.CONTENT_TYPE]: 'application/xml' },
    })
        .then(async (res) => ({
            blob: await res.blob(),
        }))
        .then((response) => {
            const newBlob = new Blob([response.blob], {
                type: 'application/xml',
            });
            downloadFile(newBlob, `invoice-${invoiceId}.xml`);
        });
};

export const updateInvoice = async (
    invoice: AtLeast<Partial<WithCustomFieldsPayload<Omit<Invoice, 'custom_fields'>>>, 'id'>
) => {
    return patch(`${ENDPOINT}/${invoice.id}`, invoice);
};

export const getInvoicePdf = async (id: string | string[]) => {
    await get(`${ENDPOINT}/${id}/pdf`, {
        headers: { [Headers.CONTENT_TYPE]: 'application/pdf' },
    })
        .then(async (res) => ({
            blob: await res.blob(),
        }))
        .then((response) => {
            const newBlob = new Blob([response.blob], {
                type: 'application/pdf',
            });
            downloadFile(newBlob, `invoice-${id}.pdf`);
        });
};

export const creditInvoice = (invoiceId: string, invoiceCredit: InvoiceCredit) => {
    return handleResponse<Invoice>({
        request: post(`${ENDPOINT}/${invoiceId}/credit`, invoiceCredit),
    });
};

export const refreshInvoice = (invoiceId: string, value?: 'FULL' | 'CUSTOMER') => {
    return handleResponse<Invoice>({
        request: post(`${ENDPOINT}/${invoiceId}/refresh`, { type: value ?? 'FULL' }),
    });
};

//we need to call three different endpoints to get the three different reports three times
export const getInvoiceReport = async (
    reportType: 'INVOICE_HEADER_DATA' | 'INVOICE_LINE_DATA' | 'TAX_SUMMARIES',
    statuses: string[],
    startAt?: string,
    endAt?: string
) => {
    let url;
    if (reportType === 'INVOICE_HEADER_DATA') {
        url = 'invoice-headers-overview';
    } else if (reportType === 'INVOICE_LINE_DATA') {
        url = 'invoice-lines-overview';
    } else if (reportType === 'TAX_SUMMARIES') {
        url = 'invoice-tax-overview';
    }

    const queryParams = withoutEmptyProperties({
        statuses,
        invoice_date: startAt || endAt ? `[${startAt || '*'},${endAt || '*'}]` : null,
    });

    get(`${import.meta.env.TRANSACTION_URL}/reports/${url}${serializeQueryParams(queryParams)}`)
        .then(async (res) => ({
            blob: await res.blob(),
        }))
        .then((response) => {
            const newBlob = new Blob([response.blob], { type: 'text/csv' });
            downloadFile(newBlob, `invoice-${reportType}.csv`);
        });
};

export const getUsageReport = async (id: string | string[]) => {
    await get(`${import.meta.env.TRANSACTION_URL}/reports/ingest-data-for-invoice?invoice_id=${id}`)
        .then(async (res) => ({
            blob: await res.blob(),
        }))
        .then((response) => {
            const newBlob = new Blob([response.blob], { type: 'text/csv' });
            downloadFile(newBlob, `usage-report-${id}.csv`);
        });
};

export const invoiceIsEditable = (invoice: Invoice) => {
    return ['DRAFT', 'OPEN'].includes(invoice.status);
};

export const invoiceIsCreditable = (invoice: Invoice) => {
    return invoice.status === 'FINAL' && invoice.type !== 'CREDIT';
};

export const createOneOffInvoice = (invoice: OneOffInvoice) => {
    return handleResponse<Invoice>({
        request: post(`${ENDPOINT}`, invoice),
    });
};

export const createInvoiceLine = (invoiceId: Invoice['id'], invoiceLine: OneOffInvoiceLine) => {
    return handleResponse<Invoice>({
        request: post(`${ENDPOINT}/${invoiceId}/add-line`, invoiceLine),
    });
};

export const deleteInvoiceLine = (
    invoiceId: Invoice['id'],
    value: { periodOrder: number; groupOrder: number; lineOrder: number }
) => {
    return handleResponse<Invoice>({
        request: post(`${ENDPOINT}/${invoiceId}/delete-line`, {
            periods: [
                {
                    period_order: value.periodOrder,
                    groups: [
                        {
                            group_order: value.groupOrder,
                            lines: [
                                {
                                    line_order: value.lineOrder,
                                },
                            ],
                        },
                    ],
                },
            ],
        }),
    });
};

export const pushToERP = (invoiceId: Invoice['id']) => {
    return handleResponse({
        request: post(`${ENDPOINT}/${invoiceId}/export_to_erp`),
    });
};

export const sendByEmail = (invoiceId: Invoice['id']) => {
    return handleResponse({
        request: post(`${ENDPOINT}/${invoiceId}/send_by_email`),
    });
};

export const getPayments = (invoiceId: Invoice['id']) => {
    return handleResponse<Payment>({
        request: get(`${ENDPOINT}/${invoiceId}/payment-attempts`),
        isCollection: true,
    });
};
