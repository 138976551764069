import { ref } from 'vue';
import { useRawIntl } from '@/plugins';

const error = ref<string>();

export const useBlockingErrorModal = () => {
    const { formatMessage } = useRawIntl();
    const defaultMessage = formatMessage({
        defaultMessage:
            "Something went wrong with your request and we've notified our engineers. Please try again later.",
        id: 'LTYeRd',
    });

    const handleClose = () => {
        error.value = undefined;
    };

    const set = (message?: string) => {
        error.value = message ?? defaultMessage;
    };

    return {
        close: handleClose,
        error,
        set,
    };
};
