import type { ApplicationStorageData } from './useApplicationStorage.types';

const LOCAL_STORAGE_KEY = 'appData';

const useApplicationStorage = () => {
    const set = (key: keyof ApplicationStorageData, value: string) => {
        const data = getData();

        setData(data ? { ...data, [key]: value } : { [key]: value });
    };

    const get = (key: keyof ApplicationStorageData) => {
        const data = getData();

        return data ? data[key] : null;
    };

    const remove = (key: keyof ApplicationStorageData): void => {
        const data = getData();

        if (!data) return;

        if (key && data[key]) {
            delete data[key];
        }

        if (typeof data === 'object' && Object.entries(data).length === 0) {
            removeData();
            return;
        }

        setData(data);
    };

    const getData = (): Nullable<ApplicationStorageData> => {
        const data = sessionStorage.getItem(LOCAL_STORAGE_KEY);

        if (!data) return null;

        const parsed = JSON.parse(atob(data)) as ApplicationStorageData;

        return parsed;
    };

    const setData = (data: ApplicationStorageData) => {
        const stringified = JSON.stringify(data);

        sessionStorage.setItem(LOCAL_STORAGE_KEY, btoa(stringified));
    };

    const removeData = () => {
        sessionStorage.removeItem(LOCAL_STORAGE_KEY);
    };

    return { get, remove, set };
};

export default useApplicationStorage;
