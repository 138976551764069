import { type Ref, type App, computed } from 'vue';
import type { Permission, Privilege } from '@solvimon/types';
import { useApp } from '@/composables';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $rbac: ReturnType<typeof getRbac>;
    }
}

let rbac: {
    privileges: Ref<Privilege[]>;
    hasPrivilege: Function;
};

const getRbac = () => {
    if (rbac) return rbac;

    const App = useApp();
    const privileges = computed(() => App.userInfo?.value?.privileges ?? []);

    const hasPrivilege = (privilege: Privilege['privilege']) =>
        !!privileges.value.find((item) => item.privilege === privilege);

    return {
        privileges,
        hasPrivilege,
    };
};

export const useRbac = () => {
    const rbac = getRbac();

    return {
        ...rbac,
        triggerGuardedRequests: (
            requests: {
                request: Promise<unknown>;
                requiredPrivileges?: Permission[];
            }[]
        ) => {
            return Promise.all(
                requests.filter(({ requiredPrivileges }) =>
                    (requiredPrivileges ?? []).every((privilege) => rbac.hasPrivilege(privilege))
                )
            );
        },
    };
};

export const rbacPlugin = {
    install(app: App) {
        const rbac = getRbac();

        if (!rbac) {
            throw 'Rbac not initialized.';
        }

        app.config.globalProperties.$rbac = getRbac();
    },
};
