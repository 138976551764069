import type { TimePeriodType } from '@solvimon/types';
import { useRawIntl } from '@/plugins';

const intl = useRawIntl();

type TimePeriodMap = Record<
    TimePeriodType,
    {
        literal: {
            /**
             * The literal singular textual representation, for example `Week`.
             */
            singular: string;
            /**
             * The literal plural textual representation, for example `Week`.
             */
            plural: string;
        };
        /**
         * The full textual representation, for example `Weekly`.
         */
        full: string;
    }
>;

/**
 * Map of supported time periods, in chronological order.
 */
export const timePeriodMap = {
    DAY: {
        literal: {
            singular: intl.formatMessage({
                defaultMessage: 'Day',
                id: '7pXrnq',
            }),
            plural: intl.formatMessage({
                defaultMessage: 'Days',
                id: 'd8EqQY',
            }),
        },
        full: intl.formatMessage({
            defaultMessage: 'Daily',
            description: 'Full label for the daily time period',
            id: '9YSziu',
        }),
    },
    WEEK: {
        literal: {
            singular: intl.formatMessage({
                defaultMessage: 'Week',
                id: 'sd2ieZ',
            }),
            plural: intl.formatMessage({
                defaultMessage: 'Weeks',
                id: 'g6+IGw',
            }),
        },
        full: intl.formatMessage({
            defaultMessage: 'Weekly',
            description: 'Full label for the weekly time period',
            id: 'nkrIJZ',
        }),
    },
    MONTH: {
        literal: {
            singular: intl.formatMessage({
                defaultMessage: 'Month',
                id: 'TBovrx',
            }),
            plural: intl.formatMessage({
                defaultMessage: 'Months',
                id: 'AxDOiG',
            }),
        },
        full: intl.formatMessage({
            defaultMessage: 'Monthly',
            description: 'Full label for the monthly time period',
            id: 'E9aHaA',
        }),
    },
    YEAR: {
        literal: {
            singular: intl.formatMessage({
                defaultMessage: 'Year',
                id: 'IFo1oo',
            }),
            plural: intl.formatMessage({
                defaultMessage: 'Years',
                id: 'Jr5tMR',
            }),
        },
        full: intl.formatMessage({
            defaultMessage: 'Yearly',
            description: 'Full label for the yearly time period',
            id: 'PiXbGw',
        }),
    },
} satisfies TimePeriodMap;
