import { getDefaultHeaders, getErrorTrackingParams, handleError, isApiErrorResponse } from './lib';
import type { ApiErrorResponse } from './types';
import hasAccessToken from '@/utils/hasAccessToken';
import router from '@/router';
import { Headers } from '@/constants';
import { trackSentryException } from '@/utils/errorTracking';

type Options = {
    disableAccessCheck?: boolean;
    notificationOnError?: boolean;
    headers?: Record<string, string | null>;
    logoutOnUnauthorized?: boolean;
};

export default async (endpoint: string, data: object = {}, options: Options = {}) => {
    const defaultOptions: Options = { notificationOnError: true };

    const mergedOptions = { ...defaultOptions, ...options };

    const executeRequest = () =>
        fetch(endpoint, {
            method: 'POST',
            headers: getDefaultHeaders(options.headers),
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then(async (response) => {
                const json = await response.json().catch((error) => {
                    // Log to console, to make debugging easier. Also for back-end devs
                    // eslint-disable-next-line no-console
                    console.error(error);
                    trackSentryException(error, { Message: 'Failed parsing JSON from api' });

                    throw {
                        hasError: true,
                        statusCode: response.status,
                        requestId: response.headers.get(Headers.X_REQUEST_ID),
                    };
                });

                if (!response.ok) {
                    throw {
                        hasError: true,
                        statusCode: response.status,
                        message: json?.message,
                        requestId: response.headers.get(Headers.X_REQUEST_ID),
                        field: json?.field,
                    };
                }

                return json;
            })
            .catch(async (error: ApiErrorResponse | unknown) => {
                trackSentryException(
                    isApiErrorResponse(error)
                        ? new Error(`POST: ${endpoint} - ${error.message}`)
                        : new Error(`POST: ${endpoint}`),
                    {
                        Payload: data,
                        Error: error,
                        ...getErrorTrackingParams(error),
                    }
                );

                await handleError(
                    {
                        message: isApiErrorResponse(error) ? error.message : '',
                        statusCode: isApiErrorResponse(error) ? error.statusCode : undefined,
                        requestId: isApiErrorResponse(error) ? error.requestId : undefined,
                    },
                    {
                        showToastOnError: mergedOptions.notificationOnError ?? false,
                        logoutOnUnauthorized: mergedOptions.logoutOnUnauthorized ?? true,
                    }
                );

                return Promise.reject(error);
            });

    if (!mergedOptions.disableAccessCheck) {
        if (await hasAccessToken()) {
            return executeRequest();
        }
        router.push({ name: 'login' });
        return;
    }

    return executeRequest();
};
