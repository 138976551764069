import { useBlockingErrorModal } from '@/components/BlockingErrorModal/useBlockingErrorModal';

export const trackSentryException = (error: unknown, scopeExtras?: Record<string, unknown>) => {
    import('@sentry/vue').then((Sentry) => {
        Sentry.captureException(error, (scope) => {
            Object.entries(scopeExtras ?? {}).forEach(([key, extra]) => {
                scope.setExtra(key, extra);
            });
            return scope;
        });
    });
};

export const trackSentryExceptionWithBlockingModal = ({
    error,
    scopeExtras,
    modalMessage,
}: {
    error: unknown;
    scopeExtras?: Record<string, unknown>;
    modalMessage?: string;
}) => {
    useBlockingErrorModal().set(modalMessage);
    trackSentryException(error, scopeExtras);
};
