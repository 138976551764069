import { ref } from 'vue';
import type {
    CustomField,
    CustomFieldConfig,
    CustomFieldPayload,
    CustomFieldsResourceType,
} from '@solvimon/types';
import { getCustomFields } from '@/services/customFields';
import { isMatchingCustomField } from '@/utils/customFields';

const customFields = ref<CustomFieldConfig[]>([]);

/**
 * A composable to manage custom fields throughout the application. Because the `custoMFields`
 * ref is defined in the global scope, this ref will stay populated until a hard refresh. This
 * composable acts like a composable with a singleton value.
 */
export default () => {
    /**
     * Fetch all custom fields and store it in local state.
     */
    const get = async () =>
        getCustomFields()
            .then((response) => {
                customFields.value = response.data;
                Promise.resolve();
            })
            .catch(() => {
                customFields.value = [];
            });

    /**
     * Get all custom fields for a specific resource type from local state.
     */
    const getByResourceType = (type: CustomFieldsResourceType) =>
        customFields.value?.filter((customField) => customField.resource_type === type);

    /**
     * Get the custom field config by custom field payload.
     */
    const getConfig = (fieldPayload: CustomFieldPayload) => {
        return customFields.value.find((field) => isMatchingCustomField(field, fieldPayload));
    };

    /**
     * Add a custom field to the local state.
     */
    const add = (customField: CustomFieldConfig) => {
        const matchIndex = customFields.value.findIndex(
            (item) =>
                (item.id && item.id === customField.id) ||
                (item.reference && item.reference === customField.reference)
        );

        if (matchIndex !== -1) {
            customFields.value[matchIndex] = customField;
        } else {
            customFields.value = [...customFields.value, customField];
        }
    };

    /**
     * Delete empty values in custom fields.
     */
    const customFieldsWithoutEmptyValues = <T extends CustomField | CustomFieldPayload>(
        customFields: T[] | undefined
    ) => customFields?.filter((field) => field.value?.length || field.values?.length) ?? [];

    const getFieldLabel = (customField: CustomFieldPayload): string => {
        const config = getConfig(customField);
        return config?.name ?? config?.reference ?? 'Unknown';
    };

    return {
        add,
        get,
        getByResourceType,
        getConfig,
        customFields,
        customFieldsWithoutEmptyValues,
        getFieldLabel,
    };
};
