<script lang="ts" setup>
import { onKeyStroke, useResizeObserver } from '@vueuse/core';
import { ref, watch, computed } from 'vue';
import { IconTitle, Typography } from '@solvimon/ui';
import type { ModalEmits, ModalProps } from '@/components/Modal/Modal.types';
import CloseButton from '@/components/CloseButton.vue';
import { Portal } from '@/components/Portal';
import Backdrop from '@/components/Backdrop/Backdrop.vue';
import { Fade } from '@/components/Transitions';
import { useShowOnTop, useApp } from '@/composables';
import { Button } from '@/components/Button';

const props = withDefaults(defineProps<ModalProps>(), {
    children: () => [],
    position: 'center',
    size: '2xl',
    noCloseButton: false,
    noClickAway: false,
    showModal: false,
    blurred: false,
});

const emit = defineEmits<ModalEmits>();

const { isEmbedded } = useApp();
const { getZIndex, trackingClass, defaultZIndex } = useShowOnTop();

const contentElementRef = ref<HTMLDivElement>();
const isContentScrollable = ref(false);
const zIndex = ref(defaultZIndex);

const appliedBlur = computed(() => props.blurred || isEmbedded.value);

const modalSizeClasses = {
    xs: 'max-w-xs',
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    '7xl': 'max-w-7xl',
};

const closeModal = () => emit('close');

onKeyStroke('Escape', () => {
    if (props.noClickAway) return;

    emit('close');
});

useResizeObserver(contentElementRef, (entries) => {
    // Prevent 'loop limit exceeded' error
    window.requestAnimationFrame(() => {
        const entry = entries[0];
        const { scrollHeight, clientHeight } = entry.target;
        isContentScrollable.value = scrollHeight > clientHeight;
    });
});

watch(
    () => props.showModal,
    (value) => {
        if (!value) return;
        zIndex.value = value ? getZIndex() : defaultZIndex;
    }
);
</script>

<template>
    <Portal to="modal">
        <Fade>
            <div
                v-if="showModal"
                class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-60 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
                :class="trackingClass"
                :style="{ zIndex }"
            >
                <Backdrop :blurred="appliedBlur" :color="isEmbedded ? 'white' : undefined" />
                <div
                    class="relative p-4 w-full h-full md:h-auto"
                    :class="`${modalSizeClasses[size]}`"
                >
                    <!-- Modal content -->
                    <div
                        class="relative flex flex-col bg-white rounded-lg shadow-lg max-h-[min(95vh,920px)]"
                    >
                        <!-- Header -->
                        <div
                            class="p-6 rounded-t relative"
                            :class="{
                                'border-b border-gray-100': !icon,
                            }"
                        >
                            <slot name="header">
                                <IconTitle
                                    v-if="title"
                                    :title="title"
                                    :icon="icon"
                                    :variant="iconVariant"
                                    stacked
                                />
                            </slot>
                            <CloseButton
                                v-if="!noCloseButton"
                                class="absolute top-3 right-3 border-none"
                                @click="closeModal"
                            />

                            <Typography
                                v-if="$slots.description"
                                variant="body-sm"
                                class="text-gray-500"
                                ><slot name="description"
                            /></Typography>
                        </div>
                        <!-- /Header-->

                        <!-- Body -->
                        <div
                            v-if="$slots.body"
                            ref="contentElementRef"
                            class="p-6 pt-3 overflow-auto"
                            :class="{ 'pt-0': !$slots.header, 'overflow-visible': overflowVisible }"
                        >
                            <slot name="body" :is-content-scrollable="isContentScrollable" />
                        </div>
                        <!-- /Body -->

                        <!--  Footer -->
                        <div
                            v-if="$slots.footer || confirmButtonText || cancelButtonText"
                            class="px-6 pb-6 rounded-b"
                            :class="{
                                'pt-6 border-t': isContentScrollable,
                            }"
                        >
                            <slot name="footer">
                                <div class="flex gap-2 flex-col">
                                    <Button autofocus @click="$emit('confirm')">
                                        {{
                                            confirmButtonText ||
                                            $t({
                                                defaultMessage: 'Confirm',
                                                id: 'N2IrpM',
                                            })
                                        }}
                                    </Button>
                                    <Button color="gray" variant="outline" @click="$emit('close')">
                                        {{
                                            cancelButtonText ||
                                            $t({ defaultMessage: 'Cancel', id: '47FYwb' })
                                        }}
                                    </Button>
                                </div>
                            </slot>
                        </div>
                        <!-- /Footer -->
                    </div>
                </div>
            </div>
        </Fade>
    </Portal>
</template>
