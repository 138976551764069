import type {
    QuoteVersion,
    QuoteVersionActionAccept,
    QuoteVersionActionRejectPayload,
    QuoteVersionActionSend,
    QuoteVersionPayload,
} from '@solvimon/types';
import { downloadFile } from '@solvimon/ui';
import { handleResponse } from './lib';
import { del, get, patch, post } from '@/callcenter';
import { Headers } from '@/constants';

const ENDPOINT = `${import.meta.env.CONFIG_URL}/quote-versions`;

/**
 * Create a new quote version.
 */
export const createQuoteVersion = (data: QuoteVersionPayload) =>
    handleResponse<QuoteVersion>({
        request: post(ENDPOINT, { ...data, object_type: 'QUOTE_VERSION' }),
    });

/**
 * Update a quote version.
 */
export const updateQuoteVersion = (id: QuoteVersion['id'], data: Partial<QuoteVersionPayload>) =>
    handleResponse<QuoteVersion>({
        request: patch(`${ENDPOINT}/${id}`, data),
    });

/**
 * Get a quote version.
 */
export const getQuoteVersion = (id: QuoteVersion['id']) =>
    handleResponse<QuoteVersion>({
        request: get(`${ENDPOINT}/${id}`),
    });

/**
 * Send a quote version to the customer.
 */
export const sendQuoteVersion = (id: QuoteVersion['id'], data: QuoteVersionActionSend) =>
    handleResponse<QuoteVersion>({
        request: post(`${ENDPOINT}/${id}/send`, data),
    });

/**
 * Accept a quote version.
 */
export const acceptQuoteVersion = (id: QuoteVersion['id'], data: QuoteVersionActionAccept) =>
    handleResponse<QuoteVersion>({
        request: post(`${ENDPOINT}/${id}/accept`, data),
    });

/**
 * Reject a quite version.
 */
export const rejectQuoteVersion = (id: QuoteVersion['id'], data: QuoteVersionActionRejectPayload) =>
    handleResponse<QuoteVersion>({
        request: post(`${ENDPOINT}/${id}/reject`, data),
    });

/**
 * Create a subscription from a quite version.
 */
export const createSubscriptionFromQuoteVersion = (id: QuoteVersion['id']) =>
    handleResponse<QuoteVersion>({
        request: post(`${ENDPOINT}/${id}/create-subscription`),
    });

/**
 * Delete a quote version.
 */
export const deleteQuoteVersion = (id: QuoteVersion['id']) =>
    handleResponse({ request: del(`${ENDPOINT}/${id}`) });

/**
 * Download the PDF version of the quote.
 */
export const getQuotePdf = async (id: QuoteVersion['id']) => {
    await get(`${ENDPOINT}/${id}/pdf`, {
        headers: { [Headers.CONTENT_TYPE]: 'application/pdf' },
    })
        .then(async (res) => ({
            blob: await res.blob(),
        }))
        .then((response) => {
            const newBlob = new Blob([response.blob], {
                type: 'application/pdf',
            });
            downloadFile(newBlob, `quote-${id}.pdf`);
        });
};

/**
 * Finalize a quote version.
 */
export const finalizeQuoteVersion = (id: QuoteVersion['id']) =>
    handleResponse<QuoteVersion>({
        request: post(`${ENDPOINT}/${id}/finalize`),
    });
