import jwt_decode from 'jwt-decode';
import type { JWT } from '@solvimon/types';
import { cookie } from './cookie';

const ACCESS_TOKEN_COOKIE_NAME = 'access_token';

export const getAccessToken = (): string | undefined => cookie.get(ACCESS_TOKEN_COOKIE_NAME);

export const getAccessTokenParsed = (): JWT | undefined => {
    const token = cookie.get(ACCESS_TOKEN_COOKIE_NAME);

    if (!token) return undefined;

    try {
        return jwt_decode(token);
    } catch {
        removeAccessToken();
        return undefined;
    }
};

export const setAccessToken = (value: string, expiresIn = 300): void =>
    cookie.set(ACCESS_TOKEN_COOKIE_NAME, value, { expiresIn });

export const removeAccessToken = (): void => cookie.remove(ACCESS_TOKEN_COOKIE_NAME);
