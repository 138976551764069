<script setup lang="ts">
import { onMounted, ref, onUnmounted } from 'vue';
import { ConfirmationModal } from '@/components/ConfirmationModal';
import { trackSentryException } from '@/utils/errorTracking';

const EVENT_NAME = 'vite:preloadError';
const hasDynamicImportError = ref(false);

const onDynamicImportError = (event: Event) => {
    hasDynamicImportError.value = true;
    trackSentryException('Dynamic import failed', { event });
};

onMounted(() => {
    window.addEventListener(EVENT_NAME, onDynamicImportError);
});

onUnmounted(() => {
    window.removeEventListener(EVENT_NAME, onDynamicImportError);
});

const reloadBrowser = () => {
    window.location.reload();
};

const closeModal = () => {
    hasDynamicImportError.value = false;
};
</script>

<template>
    <ConfirmationModal
        :show-modal="hasDynamicImportError"
        size="md"
        :title="
            $t({
                defaultMessage: 'Please reload your page',
                id: 'OEOVgf',
            })
        "
        @confirm="reloadBrowser"
        @cancel="closeModal"
    >
        <template #body>
            <slot>
                <p class="text-center">
                    {{
                        $t({
                            defaultMessage:
                                "We've encountered issues requesting resources for the page you're visiting. We possibly did a release during your session. Or you might not be able to reach our server.",
                            id: 'C2bu/z',
                        })
                    }}
                </p>
            </slot>
        </template>
        <template #confirm-button-text>
            <slot name="cancel-button-text">
                {{
                    $t({
                        defaultMessage: 'Reload page',
                        id: 'RtfJfe',
                    })
                }}
            </slot>
        </template>
        <template #cancel-button-text>
            <slot name="cancel-button-text">
                {{
                    $t({
                        defaultMessage: 'Stay on current page',
                        id: '8PS2+4',
                    })
                }}
            </slot>
        </template>
    </ConfirmationModal>
</template>
