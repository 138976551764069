type ResourcePrefix =
    | 'bile' // Billing Entity
    | 'cont' // Contact
    | 'cusf' // Custom Field
    | 'cust' // Customer
    | 'feat' // Feature
    | 'file' // File
    | 'intg' // Integration
    | 'invo' // Invoice
    | 'mdat' // Meter Data
    | 'metp' // Meter Property
    | 'metr' // Meter
    | 'metv' // Meter Value
    | 'mter' // Meter
    | 'paya' // Payment Acceptor
    | 'plat' // Platform
    | 'ppla' // Pricing Plan
    | 'ppsu' // Pricing Plan Subscription
    | 'ppve' // Pricing Plan Version
    | 'pric' // Pricing
    | 'prig' // Pricing Group
    | 'proc' // Product Category
    | 'prod' // Product
    | 'proi' // Product Item
    | 'quot' // Quote
    | 'quve' // Quote Version
    | 'user'; // User

export const getResourceIdRegex = (resourcePrefix: ResourcePrefix) => {
    return `${resourcePrefix}_[a-zA-Z0-9]+`;
};
