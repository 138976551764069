import { ref } from 'vue';
import type { CriticalErrorModalProps } from './CriticalErrorModal.types';

const errors = ref<CriticalErrorModalProps[]>([]);

export const useCriticalError = () => {
    const push = (error: CriticalErrorModalProps) => {
        if (errors.value.find((item) => item.requestId === error.requestId)) return;

        errors.value = [...errors.value, error];
    };

    const reset = () => {
        errors.value = [];
    };

    return { errors, reset, push };
};
