import { ref } from 'vue';
import type { ToastProps } from '@/components/Toaster/Toast.types';

const queue = ref<ToastProps[]>([]);

export default () => {
    const getItemKey = (item: (typeof queue.value)[number]) => `${item.type}-${item.message}`;

    const add = (item: ToastProps) => {
        queue.value.push(item);
    };

    return { queue, getItemKey, add };
};
