import { ref } from 'vue';
import type { CopiedValue, CopyFn } from './useClipboard.types';

export default (): [CopyFn, CopiedValue] => {
    const copiedText = ref<Nullable<string>>(null);

    const copy: CopyFn = async (text) => {
        if (!navigator?.clipboard) {
            // eslint-disable-next-line no-console
            console.warn('Clipboard not supported');
            return false;
        }

        try {
            await navigator.clipboard.writeText(text);
            copiedText.value = text;
            return true;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Copy failed', error);
            copiedText.value = null;
            return false;
        }
    };

    return [copy, copiedText.value];
};
