import type { ButtonSize, IconSize } from '@solvimon/ui';

export const getIconSize = (size?: ButtonSize): IconSize => {
    switch (size) {
        case 'sm':
            return 'xs';
        case 'md':
        default:
            return 'sm';
        case 'lg':
            return 'md';
    }
};
