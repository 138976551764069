import { getDefaultHeaders, getErrorTrackingParams, handleError, isApiErrorResponse } from './lib';
import type { ApiErrorResponse } from './types';
import type { GetOptions } from './get.types';
import router from '@/router';
import hasAccessToken from '@/utils/hasAccessToken';
import { Headers } from '@/constants';
import { trackSentryException } from '@/utils/errorTracking';

export default async (endpoint: string, options?: GetOptions) => {
    if (await hasAccessToken()) {
        return await fetch(endpoint, {
            method: 'GET',
            headers: getDefaultHeaders(options?.headers),
        })
            .then(async (response) => {
                const contentType = response.headers.get(Headers.CONTENT_TYPE);

                const parsedResponse = contentType?.includes('application/json')
                    ? await response.json().catch((error) => {
                          // Log to console, to make debugging easier. Also for back-end devs
                          // eslint-disable-next-line no-console
                          console.error(error);
                          trackSentryException(error, { Message: 'Failed parsing JSON from api' });

                          throw {
                              hasError: true,
                              statusCode: response.status,
                              requestId: response.headers.get(Headers.X_REQUEST_ID),
                          };
                      })
                    : response;

                if (!response.ok) {
                    throw {
                        statusCode: response.status,
                        requestId: response.headers.get(Headers.X_REQUEST_ID),
                        message: parsedResponse.message,
                        hasError: true,
                    };
                }

                return parsedResponse;
            })
            .catch(async (error: ApiErrorResponse | unknown) => {
                trackSentryException(
                    isApiErrorResponse(error)
                        ? new Error(`GET: ${endpoint} - ${error.message}`)
                        : new Error(`GET: ${endpoint}`),
                    getErrorTrackingParams(error)
                );

                await handleError(
                    {
                        message: isApiErrorResponse(error) ? error.message : '',
                        statusCode: isApiErrorResponse(error) ? error.statusCode : undefined,
                        requestId: isApiErrorResponse(error) ? error.requestId : undefined,
                    },
                    { showToastOnError: options?.showToastOnError ?? true }
                );

                return Promise.reject(error);
            });
    }
    // if no valid token was found and refreshing the token failed: require a new login
    router.push({ name: 'login' });
};
