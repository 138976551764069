import type { AccountGroupExpanded, Environment } from '@solvimon/types';
import { getAccessTokenParsed } from './accessToken';
import { getAccountById, getFirstAccount, getFirstAccountForEnvironment } from './accountGroups';
import { useApp } from '@/composables';
import config from '@/config';
import type { Platform } from '@/services/platforms.types';

/**
 * Checks whether the supplied platform id is valid for the supplied environment.
 */
const isValidPlatformIdForEnvironment = ({
    accountGroups,
    environment,
    platformId,
}: {
    accountGroups: AccountGroupExpanded[];
    environment: Environment;
    platformId: Platform['id'];
}): boolean => {
    const account = getAccountById({ accountGroups, id: platformId });

    return account ? account.environment === environment : false;
};

/**
 * Get a valid platform id
 */
export const getValidPlatform = ({
    accountGroups,
}: {
    accountGroups: AccountGroupExpanded[];
}): { platformId: Platform['id']; environment: Environment } => {
    const currentEnvironment = config.environment;

    const platformId = useApp().platformId.value;

    if (
        platformId &&
        isValidPlatformIdForEnvironment({
            accountGroups,
            platformId,
            environment: currentEnvironment,
        })
    ) {
        return { platformId, environment: currentEnvironment };
    }

    /**
     * When no platform cookie is set, take the one from the JWT. Always check if the
     * platform id provided in the JWT is valid for the current environment. If not,
     * reset the platform id.
     */
    const jwtPlatformId = getAccessTokenParsed()?.platform_id;
    if (
        jwtPlatformId &&
        isValidPlatformIdForEnvironment({
            accountGroups,
            platformId: jwtPlatformId,
            environment: currentEnvironment,
        })
    ) {
        return {
            platformId: jwtPlatformId,
            environment: currentEnvironment,
        };
    }

    /**
     * If by this time the platform id is still undefined, we will get the first platform id
     * for the current environment.
     */
    const firstAccountForEnvironment = getFirstAccountForEnvironment({
        accountGroups,
        environment: currentEnvironment,
    });
    if (firstAccountForEnvironment) {
        return {
            platformId: firstAccountForEnvironment.id,
            environment: firstAccountForEnvironment.environment,
        };
    }

    /**
     * If by this time the platform id is still undefined, it means that there is no
     * platform available for the current environment. In that case, we will try to find
     * the first valid platform for a different environment.
     */
    const firstAccount = getFirstAccount({ accountGroups });

    if (firstAccount) {
        return {
            platformId: firstAccount.id,
            environment: firstAccount.environment,
        };
    }

    /**
     * If by this time the platform id is still undefined, it means the user doesn't have access
     * to any platform.
     */
    throw new Error('No access to platforms');
};
