import { getCookie, removeCookie, setCookie } from 'typescript-cookie';
import type { Environment } from '@solvimon/types';
import type { Platform } from '@/services/platforms.types';

const COOKIE_PREFIX = 'platform_';

export default () => {
    const get = (environment: Environment) => getCookie(COOKIE_PREFIX + environment.toLowerCase());

    const set = (platformId: Platform['id'], environment: Environment) =>
        setCookie(COOKIE_PREFIX + environment.toLowerCase(), platformId, {
            domain:
                environment === 'DEV'
                    ? 'localhost'
                    : environment === 'BETA'
                    ? '.beta-solvimon.com'
                    : '.solvimon.com',
            sameSite: 'none',
            path: '/',
            expires: 30,
            secure: true,
        });

    const remove = (environment: Environment) =>
        removeCookie(COOKIE_PREFIX + environment.toLowerCase());

    return { set, get, remove };
};
