import type { CustomField, CustomFieldConfig, CustomFieldConfigPayload } from '@solvimon/types';
import { handleResponse } from './lib';
import { del, get, patch, post } from '@/callcenter';

const ENDPOINT = `${import.meta.env.CONFIG_URL}/custom-fields`;

/**
 * Get a single custom field.
 */
export const getCustomField = (id: CustomField['id']) =>
    handleResponse<CustomFieldConfig>({
        request: get(`${ENDPOINT}/${id}`),
    });

/**
 * Get a list of custom fields.
 */
export const getCustomFields = () =>
    handleResponse<CustomFieldConfig>({
        request: get(ENDPOINT),
        isCollection: true,
    });

/**
 * Create a new custom field.
 */
export const createCustomField = (data: CustomFieldConfigPayload) =>
    handleResponse<CustomFieldConfig>({ request: post(ENDPOINT, data) });

/**
 * Update an existing custom field.
 */
export const updateCustomField = (id: CustomFieldConfig['id'], data: CustomFieldConfigPayload) =>
    handleResponse<CustomFieldConfig>({ request: patch(`${ENDPOINT}/${id}`, data) });

/**
 * Delete a custom field.
 */
export const deleteCustomField = (id: CustomField['id']) =>
    handleResponse<CustomFieldConfig>({
        request: del(`${ENDPOINT}/${id}`),
    });
