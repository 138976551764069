<template>
    <TransitionGroup
        enter-active-class="duration-300 ease-out transform-gpu"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-300 ease-in transform-gpu"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <slot />
    </TransitionGroup>
</template>
