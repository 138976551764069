import type { CustomFieldOnResource, CustomFieldPayload } from '@solvimon/types';

export const isMatchingCustomField = (
    customField: CustomFieldOnResource,
    customFieldOnResource: CustomFieldPayload
) => {
    return (
        ('id' in customFieldOnResource && customFieldOnResource.id === customField.id) ||
        ('reference' in customFieldOnResource &&
            customFieldOnResource.reference === customField.reference)
    );
};
