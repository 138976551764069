<script setup lang="ts">
import { omit } from 'lodash-es';
import { useAttrs, computed } from 'vue';
import { Tooltip, TooltipContent, Icon, TooltipParagraph } from '@solvimon/ui';
import type { IconButtonProps } from './IconButton.types';
import { getIconSize } from './IconButton.lib';
import { Button } from '@/components/Button';

const props = defineProps<IconButtonProps>();
const attrs = useAttrs();

const size = getIconSize(props.size);

const buttonProps = computed(() => omit({ ...props, ...attrs }, 'icon', 'tooltip'));
</script>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>

<template>
    <template v-if="tooltip">
        <Tooltip>
            <Button v-bind="buttonProps" square><Icon :icon="icon" :size="size" /> </Button>
            <template #tooltip>
                <TooltipContent>
                    <TooltipParagraph>
                        {{ tooltip }}
                    </TooltipParagraph>
                </TooltipContent>
            </template>
        </Tooltip>
    </template>
    <template v-else>
        <Button v-bind="buttonProps" square><Icon :icon="icon" :size="size" /></Button>
    </template>
</template>
