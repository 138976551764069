import type {
    PricingPlanSchedule,
    PricingPlanScheduleWithCombinedPricingPlanVersion,
} from '@solvimon/types';
import { handleResponse } from './lib';
import type { CopyPricingPlanSchedulePayload } from './PricingPlanSchedules.types';
import { EXPANDED_PRICING_PLAN_VERSION_PROPERTIES } from './pricingPlanVersions';
import { del, get, patch, post } from '@/callcenter';
import type { ApiSuccessResponse } from '@/callcenter/types';
import { serializeQueryParams, withExpand } from '@/utils/api';

const ENDPOINT = `${import.meta.env.CONFIG_URL}/pricing-plan-schedules`;

export const getSchedulesBySubscriptionId = (pricing_plan_subscription_id: string) => {
    const queryParams = { pricing_plan_subscription_id };

    return handleResponse<PricingPlanSchedule>({
        request: get(`${ENDPOINT}${serializeQueryParams(queryParams)}`),
        isCollection: true,
    });
};

export function getSchedule(
    scheduleId: PricingPlanSchedule['id'],
    includeCombinedPricingPlanVersion: true
): Promise<ApiSuccessResponse<PricingPlanScheduleWithCombinedPricingPlanVersion>>;

export function getSchedule(
    scheduleId: PricingPlanSchedule['id'],
    includeCombinedPricingPlanVersion?: false
): Promise<ApiSuccessResponse<PricingPlanSchedule>>;

/**
 * Get pricing plan schedule by id
 */
export function getSchedule(
    scheduleId: PricingPlanSchedule['id'],
    includeCombinedPricingPlanVersion?: boolean
) {
    if (includeCombinedPricingPlanVersion) {
        const queryParams = withExpand({
            initialParams: { include_combined_pricing_plan_version: 'true' },
            expandParams: EXPANDED_PRICING_PLAN_VERSION_PROPERTIES.map(
                (property) => `combined_pricing_plan_version.${property}`
            ),
        });

        return get(`${ENDPOINT}/${scheduleId}${serializeQueryParams(queryParams)}`);
    }

    return handleResponse<PricingPlanSchedule>({
        request: get(`${ENDPOINT}/${scheduleId}`),
    });
}

/**
 * Create a new pricing plan schedule.
 */
export const createSchedule = (
    schedule: Partial<PricingPlanSchedule>,
    options?: {
        setPreviousEndAt?: boolean;
        setNextStartAt?: boolean;
    }
) => {
    const queryParams = {
        set_previous_end_at: options?.setPreviousEndAt ? `${options?.setPreviousEndAt}` : null,
        set_next_start_at: options?.setNextStartAt ? `${options?.setNextStartAt}` : null,
    };

    return handleResponse<PricingPlanSchedule>({
        request: post(`${ENDPOINT}${serializeQueryParams(queryParams)}`, schedule, {
            notificationOnError: false,
        }),
    });
};

/**
 * Update an existing pricing plan schedule.
 */
export const updateSchedule = (
    scheduleId: PricingPlanSchedule['id'],
    schedule: Partial<PricingPlanSchedule>,
    options: {
        setPreviousEndAt?: boolean;
        setNextStartAt?: boolean;
        showToastOnError: boolean;
    } = { showToastOnError: true }
) => {
    const queryParams = {
        set_previous_end_at: options?.setPreviousEndAt ? `${options?.setPreviousEndAt}` : null,
        set_next_start_at: options?.setNextStartAt ? `${options?.setNextStartAt}` : null,
    };

    return handleResponse<PricingPlanSchedule>({
        request: patch(`${ENDPOINT}/${scheduleId}${serializeQueryParams(queryParams)}`, schedule, {
            showToastOnError: options.showToastOnError,
        }),
    });
};

export const copySchedule = (
    originalScheduleId: PricingPlanSchedule['id'],
    newSchedule: CopyPricingPlanSchedulePayload,
    { setPreviousEndAt }: { setPreviousEndAt: boolean }
) => {
    return handleResponse<PricingPlanSchedule>({
        request: post(
            `${ENDPOINT}/${originalScheduleId}/copy${serializeQueryParams({
                set_previous_end_at: setPreviousEndAt ? 'true' : null,
            })}`,
            newSchedule
        ),
    });
};

/**
 * Delete a single schedule.
 */
export const deleteSchedule = (scheduleId: string): Promise<void> => {
    return handleResponse({
        request: del(`${ENDPOINT}/${scheduleId}`),
    });
};
