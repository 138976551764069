import { useMagicKeys, whenever } from '@vueuse/core';
import type { UseKeyCombination } from '@/composables/useKeyCombination.types';

const useKeyCombination: UseKeyCombination = (keysConfig, callback) => {
    const keys = useMagicKeys();
    const combination = keys[keysConfig];

    whenever(combination, callback);
};

export default useKeyCombination;
