import { onBeforeUnmount, onBeforeMount, ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import useUserActivity from './useUserActivity';
import { getAccessTokenParsed } from '@/utils/accessToken';
import refreshAccessToken from '@/utils/refreshAccessToken';

export const useAuthentication = () => {
    const route = useRoute();
    const { idle } = useUserActivity();

    const isTabVisible = ref(true);
    const refreshInterval = ref<ReturnType<typeof setInterval>>();

    const shouldRefreshToken = computed(() => isTabVisible.value && !idle.value);

    watch(
        () => shouldRefreshToken.value,
        (shouldRefresh) => {
            if (shouldRefresh) {
                refreshInterval.value = setInterval(() => handleRefreshToken(), 30 * 1000);
                return;
            }

            clearInterval(refreshInterval.value);
        },
        { immediate: true }
    );

    const handleRefreshToken = () => {
        // Don't perform a refresh for the login and logout page
        if (!route.name || ['login', 'logout'].includes(route.name.toString())) {
            return;
        }

        const accessTokenParsed = getAccessTokenParsed();

        if (!accessTokenParsed) {
            refreshAccessToken();
            return;
        }

        const secondsToExpiry = accessTokenParsed.exp - new Date().getTime() / 1000;

        if (secondsToExpiry > 60) {
            return;
        }

        refreshAccessToken();
    };

    const handleFocus = () => {
        isTabVisible.value = true;
    };

    const handleBlur = () => {
        /**
         * This setTimeout is needed to make sure we get the proper visibility state from the document.
         */
        setTimeout(() => {
            if (!document.hidden) {
                return;
            }

            isTabVisible.value = false;
            refreshAccessToken();
        }, 1);
    };

    onBeforeMount(() => {
        window.addEventListener('focus', handleFocus, false);
        window.addEventListener('blur', handleBlur, false);
        handleRefreshToken();
    });

    onBeforeUnmount(() => {
        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('blur', handleBlur);
    });
};
