import { setCookie, getCookie, removeCookie } from 'typescript-cookie';
import type { CookieAttributes } from 'typescript-cookie/dist/types';
import type { cookieConfig } from './cookie.config';
import type { CookieOptions } from './cookie.types';

const defaultCookieOptions: CookieAttributes = {
    expires: 30,
    sameSite: 'none',
    path: '/',
    secure: true,
};

/**
 * A cookie service to manage cookies throughout the platform.
 */
export const cookie = {
    /**
     * Get a cookie value by name.
     */
    get: (name: keyof typeof cookieConfig) => getCookie(name),
    /**
     * Set a cookie.
     */
    set: (name: keyof typeof cookieConfig, value: string | number, options: CookieOptions = {}) => {
        const { expires, expiresIn, ...nativeOptions } = options;

        /**
         * If the expires option is a function, execute it and overwrite the original value.
         */
        if (typeof expires === 'function') {
            nativeOptions.expires = expires();
        }

        /**
         * When the custom option `expiresIn` is set, transform it to an expiry date
         * and overwrite the `expires` option.
         */
        if (expiresIn) {
            nativeOptions.expires = new Date(new Date().getTime() + expiresIn * 1000);
        }

        const cookieOptions = { ...defaultCookieOptions, ...nativeOptions };
        setCookie(name, value, cookieOptions);
    },
    /**
     * Remove a cookie by name.
     */
    remove: (name: keyof typeof cookieConfig) => removeCookie(name),
};
