import { formatQueryParams } from '@/utils/queryParams';

export const withExpand = ({
    initialParams = {},
    expandParams,
    expand = true,
}: {
    initialParams?: Record<string, string | string[] | null>;
    expandParams: string[];
    expand?: boolean;
}) => (expand ? { ...initialParams, ...{ expand: expandParams } } : initialParams);

export type WithPagination<OrderBy extends string> = {
    orderBy?: OrderBy;
    orderDirection?: 'asc' | 'desc';
    page?: number;
    pageSize?: number;
};

export const withPagination = <OrderBy extends string>(
    queryParams: Record<string, string | string[] | null>,
    { page = 1, pageSize, orderBy, orderDirection }: WithPagination<OrderBy>
) => {
    const result = {
        ...(queryParams ?? {}),
        page: page.toString(),
    };

    if (pageSize) {
        Object.assign(result, { limit: pageSize });
    }

    if (orderBy) {
        Object.assign(result, { order_by: orderBy });
    }

    if (orderDirection) {
        Object.assign(result, { order_direction: orderDirection });
    }

    return result;
};

export const serializeQueryParams = (obj: Record<string, string | string[] | null>) => {
    const formattedQueryParams = formatQueryParams(obj);

    return formattedQueryParams === '' ? '' : `?${formattedQueryParams}`;
};

/**
 * Gets the error from an API response. It will return an object with the field name as a key.
 * When it is a generic error (not related to a specific field), the object key is `_generic`
 */
export const getApiError = (response: any): Record<string, string> | undefined => {
    if ('field' in response) {
        return { [response.field]: response.message };
    }

    if ('message' in response) {
        return { _generic: response.message };
    }

    return undefined;
};
