import type { Currency } from '@solvimon/types';
import { handleResponse } from './lib';
import type { Platform, PlatformEntitlements } from './platforms.types';
import { get, patch } from '@/callcenter';

const ENDPOINT = `${import.meta.env.CONFIG_URL}/platforms`;

/**
 * Get a single platform by id.
 */
export const getPlatform = (id: Platform['id']) => {
    return handleResponse<Platform>({
        request: get(`${ENDPOINT}/${id}`),
    });
};

/**
 * Update a platform by id.
 */
export const updatePlatform = (id: Platform['id'], data: Partial<Platform>) => {
    return handleResponse<Platform>({
        request: patch(`${ENDPOINT}/${id}`, data),
    });
};

/**
 * Get the platform currencies.
 */
export const getPlatformCurrencies = (id: Platform['id']) => {
    return handleResponse<Currency[]>({
        request: get(`${ENDPOINT}/${id}/currencies`),
    });
};

export const getPlatformEntitlements = (platformId: Platform['id']) => {
    return handleResponse<PlatformEntitlements>({
        request: get(`${ENDPOINT}/${platformId}/entitlements`, {}),
    });
};
