import type { RouteRecordRaw } from 'vue-router';
import { getResourceIdRegex } from '@/utils/resources';
import { useRawIntl } from '@/plugins';

const { formatMessage } = useRawIntl();

export const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'login',
        meta: {
            name: formatMessage({ defaultMessage: 'login', id: 'EHNQ95' }),
            public: true,
            platformSpecific: false,
            title: formatMessage({ defaultMessage: 'Login', id: 'AyGauy' }),
        },
        component: () => import('../views/Login/Login.vue'),
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            name: formatMessage({ defaultMessage: 'reset password', id: 'FRcW1o' }),
            public: true,
            platformSpecific: false,
            title: formatMessage({ defaultMessage: 'Reset password', id: 'Yy/yDL' }),
        },
        component: () => import('../views/Login/ResetPassword.vue'),
    },
    {
        path: '/reset',
        name: 'reset',
        meta: {
            name: formatMessage({ defaultMessage: 'create new password', id: 'o0fUTD' }),
            public: true,
            platformSpecific: false,
            title: formatMessage({ defaultMessage: 'Create new password', id: 'OpdjDW' }),
        },
        component: () => import('../views/Login/CreateNewPassword.vue'),
    },
    {
        path: '/login/callback',
        name: 'login-callback',
        meta: {
            name: 'login callback',
            public: true,
            platformSpecific: false,
            title: null,
        },
        component: () => import('../views/Login/Callback.vue'),
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            name: formatMessage({ defaultMessage: 'logout', id: '6BySgv' }),
            platformSpecific: false,
            public: true,
            title: formatMessage({ defaultMessage: 'Log out', id: 'PlBReU' }),
        },
        component: () => import('../views/LogoutView.vue'),
    },
    {
        path: '/overview',
        name: 'overview',
        meta: {
            name: formatMessage({ defaultMessage: 'overview', id: 'flJCao' }),
            title: formatMessage({ defaultMessage: 'Overview', id: '9uOFF3' }),
        },
        component: () => import('../views/Home/OverviewView.vue'),
    },
    {
        path: '/invoices',
        name: 'invoices',
        meta: {
            name: formatMessage({ defaultMessage: 'invoice overview', id: 'eY0MXU' }),
            requiredPrivileges: ['INVOICE.VIEW'],
            title: formatMessage({ defaultMessage: 'Invoices', id: '6dqYnF' }),
        },
        component: () => import('../views/Invoices/InvoiceOverview.vue'),
    },
    {
        path: `/invoices/:id(${getResourceIdRegex('invo')})`,
        name: 'invoices-detail',
        meta: {
            name: formatMessage({ defaultMessage: 'invoice detail', id: 'b4g1Sj' }),
            requiredPrivileges: ['INVOICE.VIEW'],
            title: formatMessage({ defaultMessage: 'Invoice', id: 'Ecfndm' }),
        },
        component: () => import('../views/Invoices/InvoiceDetail.vue'),
        children: [
            {
                path: `/invoices/:id(${getResourceIdRegex('invo')})`,
                name: 'invoice-detail',
                props: { panel: { isOpen: true } },
                meta: {
                    name: formatMessage({ defaultMessage: 'invoice', id: 'ZZmD0j' }),
                    requiredPrivileges: ['INVOICE.VIEW'],
                    title: formatMessage({ defaultMessage: 'Invoice', id: 'Ecfndm' }),
                },
                components: {},
            },
            {
                path: `/invoices/:id(${getResourceIdRegex('invo')})/notes`,
                name: 'invoice-add-note',
                meta: {
                    name: formatMessage({ defaultMessage: 'invoice notes', id: 's10r5k' }),
                    requiredPrivileges: ['INVOICE.VIEW', 'INVOICE.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Invoice notes ', id: 'Sxzlf/' }),
                },
                props: { panel: { action: 'create' } },
                components: {
                    panel: () => import('../views/Invoices/ManageNotes.vue'),
                },
            },
            {
                path: `/invoices/:id(${getResourceIdRegex('invo')})/edit`,
                name: 'edit-invoice',
                meta: {
                    name: formatMessage({ defaultMessage: 'edit invoice info', id: 'zvDEfA' }),
                    requiredPrivileges: ['INVOICE.VIEW', 'INVOICE.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Edit invoice', id: 'a0N52C' }),
                },
                props: { panel: { action: 'create' } },
                components: {
                    panel: () => import('../views/Invoices/ManageInvoice.vue'),
                },
            },
            {
                path: `/invoices/:id(${getResourceIdRegex('invo')})/notes/:noteIndex([0-9]+)/edit`,
                name: 'invoice-edit-note',
                meta: {
                    name: formatMessage({ defaultMessage: 'invoice notes', id: 's10r5k' }),
                    requiredPrivileges: ['INVOICE.VIEW', 'INVOICE.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Edit invoice note', id: 'SL0MGt' }),
                },
                props: { panel: { action: 'update' } },
                components: {
                    panel: () => import('../views/Invoices/ManageNotes.vue'),
                },
            },
            {
                path: `/invoices/:id(${getResourceIdRegex('invo')})/lines/add`,
                name: 'invoice-add-line',
                meta: {
                    name: formatMessage({ defaultMessage: 'add invoice line', id: 'o8MXQS' }),
                    requiredPrivileges: ['INVOICE.LINE.EDIT'],
                    title: formatMessage({ defaultMessage: 'Add invoice line', id: 'JlhdD2' }),
                },
                props: { panel: { isOpen: true } },
                components: {
                    panel: () => import('../views/Invoices/AddInvoiceLinePanel.vue'),
                },
            },
        ],
    },
    {
        path: '/products',
        name: 'products',
        meta: {
            name: formatMessage({ defaultMessage: 'product overview', id: 'MhPo4q' }),
            requiredPrivileges: ['PRODUCT.VIEW'],
            title: formatMessage({ defaultMessage: 'Products', id: '7NFfmz' }),
        },
        component: () => import('../views/Products/ProductOverview.vue'),
        children: [
            {
                path: `/products/:categoryId(${getResourceIdRegex('proc')})`,
                name: 'products-by-category',
                meta: {
                    name: formatMessage({ defaultMessage: 'products by category', id: '+L9qm8' }),
                    requiredPrivileges: ['PRODUCT.VIEW'],
                    title: formatMessage({ defaultMessage: 'Products by category', id: '9AzFng' }),
                },
                component: () => import('../views/Products/ProductOverview.vue'),
            },
            {
                path: `/products/add/:categoryId(${getResourceIdRegex('proc')})?`,
                name: 'product-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'product creation', id: 'h5FzgM' }),
                    requiredPrivileges: ['PRODUCT.CREATE'],
                    title: formatMessage({ defaultMessage: 'Create product', id: 'V85fXf' }),
                },
                components: {
                    panel: () => import('../views/Products/CreateProduct.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex(
                    'proc'
                )})/:productId(${getResourceIdRegex('prod')})/edit`,
                name: 'product-edit',
                meta: {
                    name: formatMessage({ defaultMessage: 'product edit', id: 'D/HOFQ' }),
                    requiredPrivileges: ['PRODUCT.CREATE'],
                    title: formatMessage({ defaultMessage: 'Edit product', id: 'Tk3zCB' }),
                },
                components: {
                    panel: () => import('../views/Products/EditProduct.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex(
                    'proc'
                )})/:productId(${getResourceIdRegex('prod')})`,
                name: 'product-details',
                meta: {
                    name: formatMessage({ defaultMessage: 'product details', id: 'Is6wjI' }),
                    requiredPrivileges: ['PRODUCT.VIEW'],
                    title: formatMessage({ defaultMessage: 'Product details', id: 'VYK2nN' }),
                },
                props: { panel: { isOpen: true } },
                components: {
                    panel: () => import('../views/Products/ProductDetails.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex(
                    'proc'
                )})/:productId(${getResourceIdRegex('prod')})/manage`,
                name: 'product-manage',
                meta: {
                    name: formatMessage({ defaultMessage: 'product management', id: 't3YhxG' }),
                    requiredPrivileges: ['PRODUCT.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Manage product', id: 'TZm3HV' }),
                },
                props: { panel: { isOpen: true } },
                components: {
                    panel: () => import('../views/Products/ManageProduct.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex(
                    'proc'
                )})/:productId(${getResourceIdRegex('prod')})/reorder`,
                name: 'products-reorder-items',
                meta: {
                    name: formatMessage({ defaultMessage: 'product ordering', id: 'TP9J+4' }),
                    requiredPrivileges: ['PRODUCT.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Change product order', id: 'Oaqmod' }),
                },
                props: { panel: { isOpen: true } },
                components: {
                    panel: () => import('../views/Products/reorder/ReorderProductItemsPanel.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex(
                    'proc'
                )})/:productId(${getResourceIdRegex('prod')})/revenue-items/add`,
                name: 'product-revenue-item-add',
                meta: {
                    name: formatMessage({
                        defaultMessage: 'revenue item creation',
                        id: 'ibFywt',
                    }),
                    requiredPrivileges: ['PRODUCT.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Create revenue item', id: 'd2J54J' }),
                },
                props: {
                    panel: {
                        isOpen: true,
                        type: 'REVENUE',
                    },
                },
                components: {
                    panel: () => import('../views/Products/ProductItems/CreateProductItem.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex(
                    'proc'
                )})/:productId(${getResourceIdRegex(
                    'prod'
                )})/revenue-items/:productItemId(${getResourceIdRegex('proi')})/edit`,
                name: 'product-revenue-item-edit',
                meta: {
                    name: formatMessage({
                        defaultMessage: 'revenue item management',
                        id: '57KZ36',
                    }),
                    requiredPrivileges: ['PRODUCT.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Edit revenue item', id: 'YPP1qJ' }),
                },
                props: { panel: { isOpen: true, type: 'REVENUE' } },
                components: {
                    panel: () => import('../views/Products/ProductItems/EditProductItem.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex(
                    'proc'
                )})/:productId(${getResourceIdRegex('prod')})/deduction-items/add`,
                name: 'product-deduction-item-add',
                meta: {
                    name: formatMessage({
                        defaultMessage: 'deduction item creation',
                        id: 'aJwFl9',
                    }),
                    title: formatMessage({ defaultMessage: 'Add deduction item', id: 'TiGq3q' }),
                },
                props: { panel: { isOpen: true, type: 'DEDUCTION' } },
                components: {
                    panel: () => import('../views/Products/ProductItems/CreateProductItem.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex(
                    'proc'
                )})/:productId(${getResourceIdRegex(
                    'prod'
                )})/deduction-items/:productItemId(${getResourceIdRegex('proi')})/edit`,
                name: 'product-deduction-item-edit',
                meta: {
                    name: formatMessage({
                        defaultMessage: 'deduction item management',
                        id: '1wNZBR',
                    }),
                    title: formatMessage({ defaultMessage: 'Edit deduction item', id: 'L5EoGp' }),
                },
                props: { panel: { isOpen: true, type: 'DEDUCTION' } },
                components: {
                    panel: () => import('../views/Products/ProductItems/EditProductItem.vue'),
                },
            },
            {
                path: `/products/:categoryId(${getResourceIdRegex('proc')})?/reorder`,
                name: 'products-reorder',
                meta: {
                    name: formatMessage({
                        defaultMessage: 'reorder product catalog',
                        id: 'EMv8X6',
                    }),
                    requiredPrivileges: ['PRODUCT.UPDATE'],
                    title: formatMessage({
                        defaultMessage: 'Reorder product catalog',
                        id: '66guze',
                    }),
                },
                props: {
                    panel: { isOpen: true },
                },
                components: {
                    panel: () => import('../views/Products/reorder/ReorderProductsPanel.vue'),
                },
            },
        ],
    },
    {
        path: '/pricing-plans',
        name: 'pricing-plans',
        meta: {
            name: formatMessage({ defaultMessage: 'pricing plan overview', id: 'd0zZw5' }),
            requiredPrivileges: ['PRICING_PLAN.VIEW'],
            title: formatMessage({ defaultMessage: 'Pricing plans', id: 'gwJiMs' }),
        },
        component: () => import('../views/Pricing/PricingPlanOverview.vue'),
    },
    {
        path: `/pricing-plans/:planId(${getResourceIdRegex(
            'ppla'
        )})/versions/:versionId(${getResourceIdRegex('ppve')})`,
        name: 'pricing-plan-versions',
        meta: {
            name: formatMessage({ defaultMessage: 'pricing plan version', id: 'UvwiUh' }),
            requiredPrivileges: ['PRICING_PLAN.VIEW'],
            title: formatMessage({ defaultMessage: 'Pricing plan version', id: '8QvcpK' }),
        },
        component: () => import('../views/Pricing/PricingPlanVersion.vue'),
        children: [
            {
                path: `/pricing-plans/:planId(${getResourceIdRegex(
                    'ppla'
                )})/versions/:versionId(${getResourceIdRegex('ppve')})/reorder`,
                name: 'pricing-plan-version-reorder',
                meta: {
                    name: formatMessage({ defaultMessage: 'changing pricing order', id: 'sDMGjK' }),
                    requiredPrivileges: ['PRICING_PLAN.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Change pricing order', id: 'Aqfqqo' }),
                },
                props: { panelInline: { isOpen: true } },
                components: {
                    panelInline: () => import('../views/Pricing/ReorderPricingsPanel.vue'),
                },
            },
        ],
    },
    {
        path: `/pricing-plans/:planId(${getResourceIdRegex(
            'ppla'
        )})/versions/:versionId(${getResourceIdRegex('ppve')})/:pricingGroupId(${getResourceIdRegex(
            'prig'
        )})?/pricing/:pricingId(${getResourceIdRegex('pric')})/edit`,
        name: 'pricing-quick-edit',
        meta: {
            name: formatMessage({ defaultMessage: 'edit pricing', id: 'BR3cz2' }),
            requiredPrivileges: ['PRICING_PLAN.UPDATE'],
            title: formatMessage({ defaultMessage: 'Edit pricing', id: 'oV2LI8' }),
        },
        props: { panel: { isOpen: true } },
        components: {
            default: () => import('../views/Pricing/PricingPlanVersion.vue'),
            panel: () => import('../views/Pricing/UpdatePricingPanel.vue'),
        },
    },
    {
        path: `/pricing-plans/:planId(${getResourceIdRegex(
            'ppla'
        )})/versions/:versionId(${getResourceIdRegex('ppve')})/pricing/add`,
        name: 'pricing-add',
        meta: {
            name: formatMessage({ defaultMessage: 'pricing', id: 'Gukix7' }),
            requiredPrivileges: ['PRICING_PLAN.UPDATE'],
            title: formatMessage({ defaultMessage: 'Add pricing', id: '4M7umv' }),
        },
        props: {
            panel: { isOpen: true },
        },
        components: {
            default: () => import('../views/Pricing/PricingPlanVersion.vue'),
            panel: () => import('../views/Pricing/CreatePricingPanel.vue'),
        },
    },
    {
        path: `/pricing-plans/:planId(${getResourceIdRegex(
            'ppla'
        )})/versions/:versionId(${getResourceIdRegex(
            'ppve'
        )})/pricings-groups/:pricingGroupId(${getResourceIdRegex('prig')})/pricing/add`,
        name: 'pricing-group-add-pricing',
        meta: {
            name: formatMessage({ defaultMessage: 'pricing', id: 'Gukix7' }),
            requiredPrivileges: ['PRICING_PLAN.UPDATE'],
            title: formatMessage({ defaultMessage: 'Add pricing group', id: 'Zia4RY' }),
        },
        props: {
            panel: { isOpen: true },
        },
        components: {
            default: () => import('../views/Pricing/PricingPlanVersion.vue'),
            panel: () => import('../views/Pricing/CreatePricingPanel.vue'),
        },
    },
    {
        path: `/pricing-plans/:planId(${getResourceIdRegex(
            'ppla'
        )})/versions/:versionId(${getResourceIdRegex('ppve')})/pricing-groups/add`,
        name: 'pricing-group-add',
        meta: {
            name: formatMessage({ defaultMessage: 'pricing group', id: 'kPwYK2' }),
            requiredPrivileges: ['PRICING_PLAN.UPDATE'],
            title: formatMessage({ defaultMessage: 'Add pricing group', id: 'Zia4RY' }),
        },
        props: {
            panel: { isOpen: true },
        },
        components: {
            default: () => import('../views/Pricing/PricingPlanVersion.vue'),
            panel: () => import('../views/Pricing/PricingGroupPanel.vue'),
        },
    },
    {
        path: `/pricing-plans/:planId(${getResourceIdRegex(
            'ppla'
        )})/versions/:versionId(${getResourceIdRegex(
            'ppve'
        )})/pricing-groups/:pricingGroupId(${getResourceIdRegex('prig')})/edit`,
        name: 'pricing-group-edit',
        meta: {
            name: formatMessage({ defaultMessage: 'pricing group edit', id: 'oVEkU2' }),
            requiredPrivileges: ['PRICING_PLAN.UPDATE'],
            title: formatMessage({ defaultMessage: 'Edit pricing group', id: 'Bd15je' }),
        },
        props: {
            panel: { isOpen: true },
        },
        components: {
            default: () => import('../views/Pricing/PricingPlanVersion.vue'),
            panel: () => import('../views/Pricing/PricingGroupPanel.vue'),
        },
    },
    {
        path: '/customers',
        name: 'customers',
        meta: {
            name: formatMessage({ defaultMessage: 'customer overview', id: 'ZhdFOW' }),
            requiredPrivileges: ['CUSTOMER.VIEW'],
            title: formatMessage({ defaultMessage: 'Customers', id: 'TkYZBT' }),
        },
        component: () => import('../views/Customers/CustomerOverview.vue'),
    },
    {
        path: `/customers/:customerId(${getResourceIdRegex('cust')})`,
        name: 'customer-detail',
        meta: {
            name: formatMessage({ defaultMessage: 'customer', id: 'FU51/Z' }),
            requiredPrivileges: ['CUSTOMER.VIEW'],
            title: formatMessage({ defaultMessage: 'Customer details', id: 'zgrORK' }),
        },
        component: () => import('../views/Customers/CustomerDetails.vue'),
    },
    {
        path: `/customers/edit/:customerId(${getResourceIdRegex('cust')})`,
        name: 'customer-edit',
        meta: {
            name: formatMessage({ defaultMessage: 'customer', id: 'FU51/Z' }),
            requiredPrivileges: ['CUSTOMER.VIEW', 'CUSTOMER.UPDATE'],
            title: formatMessage({ defaultMessage: 'Edit customer', id: 'aGKNbY' }),
        },
        props: { panel: { isOpen: true } },
        components: {
            default: () => import('../views/Customers/CustomerDetails.vue'),
            panel: () => import('../views/Customers/UpdateCustomer.vue'),
        },
    },
    {
        path: `/customers/edit/:customerId(${getResourceIdRegex('cust')})/contacts/add`,
        name: 'customer-contact-add',
        meta: {
            name: formatMessage({ defaultMessage: 'contact', id: 'h1k+6t' }),
            requiredPrivileges: ['CONTACT.VIEW', 'CONTACT.CREATE'],
            title: formatMessage({ defaultMessage: 'Add customer contact', id: 'KYAM8z' }),
        },
        props: { panel: { isOpen: true, mode: 'CREATE' } },
        components: {
            default: () => import('../views/Customers/CustomerDetails.vue'),
            panel: () => import('../views/Customers/Contacts/CustomerContactPanel.vue'),
        },
    },
    {
        path: `/customers/edit/:customerId(${getResourceIdRegex(
            'cust'
        )})/contacts/:contactId(${getResourceIdRegex('cont')})`,
        name: 'customer-contact-edit',
        meta: {
            name: formatMessage({ defaultMessage: 'contact', id: 'h1k+6t' }),
            requiredPrivileges: ['CUSTOMER.VIEW', 'CUSTOMER.UPDATE'],
            title: formatMessage({ defaultMessage: 'Edit customer contact', id: 'f+6Mr7' }),
        },
        props: { panel: { isOpen: true, mode: 'UPDATE' } },
        components: {
            default: () => import('../views/Customers/CustomerDetails.vue'),
            panel: () => import('../views/Customers/Contacts/CustomerContactPanel.vue'),
        },
    },
    {
        path: '/customers/add',
        name: 'customer-add',
        meta: {
            name: formatMessage({ defaultMessage: 'customer', id: 'FU51/Z' }),
            requiredPrivileges: ['CUSTOMER.CREATE'],
            title: formatMessage({ defaultMessage: 'Add customer', id: 'e7e8yl' }),
        },
        props: { panel: { isOpen: true } },
        components: {
            default: () => import('../views/Customers/CustomerOverview.vue'),
            panel: () => import('../views/Customers/CreateCustomer.vue'),
        },
    },
    {
        path: '/features',
        name: 'features',
        meta: {
            name: formatMessage({ defaultMessage: 'feature overview', id: '5IuB6R' }),
            requiredPrivileges: ['FEATURE.CREATE'],
            title: formatMessage({ defaultMessage: 'Features', id: 'ZXp0z1' }),
        },
        component: () => import('../views/Features/FeatureOverview.vue'),
    },
    {
        path: '/features/add',
        name: 'feature-add',
        meta: {
            name: formatMessage({ defaultMessage: 'feature', id: 'Gs/7//' }),
            requiredPrivileges: ['FEATURE.CREATE'],
            title: formatMessage({ defaultMessage: 'Add feature', id: 'sgmrJ3' }),
        },
        props: { panel: { isOpen: true } },
        components: {
            default: () => import('../views/Features/FeatureOverview.vue'),
            panel: () => import('../views/Features/CreateFeature.vue'),
        },
    },
    {
        path: `/features/:featureId(${getResourceIdRegex('feat')})`,
        name: 'feature-details',
        meta: {
            name: formatMessage({ defaultMessage: 'feature details', id: 'iwm9OO' }),
            requiredPrivileges: ['FEATURE.VIEW'],
            title: formatMessage({ defaultMessage: 'Feature details', id: 'jQYgoS' }),
        },
        props: { panel: { isOpen: true } },
        components: {
            default: () => import('../views/Features/FeatureOverview.vue'),
            panel: () => import('../views/Features/FeatureDetails.vue'),
        },
    },
    {
        path: `/features/:featureId(${getResourceIdRegex('feat')})/edit`,
        name: 'feature-edit',
        meta: {
            name: formatMessage({ defaultMessage: 'feature edit', id: 'mPuLG/' }),
            requiredPrivileges: ['FEATURE.VIEW', 'FEATURE.UPDATE'],
            title: formatMessage({ defaultMessage: 'Update feature', id: 'Uckk1z' }),
        },
        props: { panel: { isOpen: true } },
        components: {
            default: () => import('../views/Features/FeatureOverview.vue'),
            panel: () => import('../views/Features/EditFeature.vue'),
        },
    },
    {
        path: '/meters',
        name: 'meters',
        meta: {
            name: formatMessage({ defaultMessage: 'meters', id: 'hnrOvx' }),
            requiredPrivileges: ['METER.CREATE'],
            title: formatMessage({ defaultMessage: 'Meters', id: '7ztn7l' }),
        },
        component: () => import('../views/Meters/MeterOverview.vue'),
        props: { activeTab: 'meters' },
        children: [
            {
                path: '/meters/add',
                name: 'meter-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'meter', id: 'blegMn' }),
                    requiredPrivileges: ['METER.CREATE'],
                    title: formatMessage({ defaultMessage: 'Create meter', id: '5Br/fI' }),
                },
                props: { panel: { isOpen: true, action: 'create' } },
                components: {
                    panel: () => import('../views/Meters/Meters/ManageMeter.vue'),
                },
            },
            {
                path: `/meters/:id(${getResourceIdRegex('metr')})/edit`,
                name: 'meter-details',
                meta: {
                    name: formatMessage({ defaultMessage: 'meter details', id: 'qITYZU' }),
                    requiredPrivileges: ['METER.VIEW', 'METER.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Meter details', id: 'S5NKMC' }),
                },
                props: { panel: { isOpen: true } },
                components: {
                    panel: () => import('../views/Meters/Meters/MeterDetails.vue'),
                },
            },
            {
                path: `/meters/:id(${getResourceIdRegex('metr')})/edit`,
                name: 'meter-edit',
                meta: {
                    name: formatMessage({ defaultMessage: 'meter edit', id: 'Gt+mm2' }),
                    requiredPrivileges: ['METER.VIEW', 'METER.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Edit meter', id: 'jBgrj7' }),
                },
                props: { panel: { isOpen: true, action: 'update' } },
                components: {
                    panel: () => import('../views/Meters/Meters/ManageMeter.vue'),
                },
            },
        ],
    },
    {
        path: '/meters/properties',
        name: 'meter-properties',
        meta: {
            name: formatMessage({ defaultMessage: 'meter properties', id: 'HEALvB' }),
            requiredPrivileges: ['METER_PROPERTY.VIEW'],
            title: formatMessage({ defaultMessage: 'Meter properties', id: 'EvUB9l' }),
        },
        component: () => import('../views/Meters/MeterOverview.vue'),
        props: { activeTab: 'properties' },
    },
    {
        path: `/meters/properties/:id(${getResourceIdRegex('metp')})`,
        name: 'meter-property-details',
        meta: {
            name: formatMessage({ defaultMessage: 'meter property', id: 'nvwwwb' }),
            requiredPrivileges: ['METER_PROPERTY.VIEW'],
            title: formatMessage({ defaultMessage: 'Meter property details', id: 'xWxX1k' }),
        },
        props: { default: { activeTab: 'properties' }, panel: { isOpen: true } },
        components: {
            default: () => import('../views/Meters/MeterOverview.vue'),
            panel: () => import('../views/Meters/Properties/PropertyDetails.vue'),
        },
    },
    {
        path: `/meters/properties/:id(${getResourceIdRegex('metp')})/edit`,
        name: 'meter-property-edit',
        meta: {
            name: formatMessage({ defaultMessage: 'meter property', id: 'nvwwwb' }),
            requiredPrivileges: ['METER_PROPERTY.VIEW'],
            title: formatMessage({ defaultMessage: 'Meter property edit', id: 'YWBVoR' }),
        },
        props: { default: { activeTab: 'properties' }, panel: { isOpen: true } },
        components: {
            default: () => import('../views/Meters/MeterOverview.vue'),
            panel: () => import('../views/Meters/Properties/UpdateProperty.vue'),
        },
    },
    {
        path: '/meters/properties/add',
        name: 'meter-property-add',
        meta: {
            name: formatMessage({ defaultMessage: 'meter property', id: 'nvwwwb' }),
            requiredPrivileges: ['METER_PROPERTY.CREATE'],
            title: formatMessage({ defaultMessage: 'Add meter property', id: '2siSdX' }),
        },
        props: {
            default: { activeTab: 'properties' },
            panel: { activeTab: 'properties', isOpen: true },
        },
        components: {
            default: () => import('../views/Meters/MeterOverview.vue'),
            panel: () => import('../views/Meters/Properties/CreateProperty.vue'),
        },
    },
    {
        path: '/meters/values',
        name: 'meter-values',
        meta: {
            name: formatMessage({ defaultMessage: 'meter values', id: 'G4Y4Du' }),
            requiredPrivileges: ['METER_VALUE.VIEW'],
            title: formatMessage({ defaultMessage: 'Meter values', id: 'UAXCDU' }),
        },
        component: () => import('../views/Meters/MeterOverview.vue'),
        props: { activeTab: 'values' },
    },
    {
        path: `/meters/values/:id(${getResourceIdRegex('metv')})`,
        name: 'meter-value-details',
        meta: {
            name: formatMessage({ defaultMessage: 'meter value', id: 'rlFpHR' }),
            title: formatMessage({ defaultMessage: 'Meter value details', id: 'eDDw+F' }),
        },
        props: {
            default: { activeTab: 'values' },
            panel: { activeTab: 'values', isOpen: true },
        },
        components: {
            default: () => import('../views/Meters/MeterOverview.vue'),
            panel: () => import('../views/Meters/Values/ValueDetails.vue'),
        },
    },
    {
        path: '/meters/values/add',
        name: 'meter-value-add',
        meta: {
            name: formatMessage({ defaultMessage: 'meter value', id: 'rlFpHR' }),
            requiredPrivileges: ['METER_VALUE.CREATE'],
            title: formatMessage({ defaultMessage: 'Add meter value', id: 'wTll5d' }),
        },
        props: {
            default: { activeTab: 'values' },
            panel: { activeTab: 'values', isOpen: true },
        },
        components: {
            default: () => import('../views/Meters/MeterOverview.vue'),
            panel: () => import('../views/Meters/Values/CreateValue.vue'),
        },
    },
    {
        path: `/meters/values/:id(${getResourceIdRegex('metv')})/edit`,
        name: 'meter-value-edit',
        meta: {
            name: formatMessage({ defaultMessage: 'meter value', id: 'rlFpHR' }),
            requiredPrivileges: ['METER_VALUE.VIEW'],
            title: formatMessage({ defaultMessage: 'Meter value edit', id: 'FhHYu6' }),
        },
        props: { default: { activeTab: 'values' }, panel: { isOpen: true } },
        components: {
            default: () => import('../views/Meters/MeterOverview.vue'),
            panel: () => import('../views/Meters/Values/UpdateValue.vue'),
        },
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        meta: {
            name: formatMessage({ defaultMessage: 'subscriptions', id: 'XINjmO' }),
            requiredPrivileges: ['PRICING_PLAN_SUBSCRIPTION.VIEW'],
            title: formatMessage({ defaultMessage: 'Subscriptions', id: 'J+dIsA' }),
        },
        component: () => import('../views/Subscriptions/SubscriptionOverview.vue'),
        children: [
            {
                path: `/subscriptions/add:customerId(${getResourceIdRegex('cust')})?`,
                name: 'subscriptions-subscription-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'add subscription', id: 'ZlqQAP' }),
                    requiredPrivileges: ['PRICING_PLAN_SUBSCRIPTION.CREATE'],
                    title: formatMessage({ defaultMessage: 'Add subscription', id: '16BslC' }),
                },
                components: {
                    panel: () => import('../views/Subscriptions/CreateSubscription.vue'),
                },
            },
            {
                path: '/subscription/add',
                name: 'subscriptions-subscription-groups-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'add subscription group', id: 'uoDiyU' }),
                    requiredPrivileges: ['PRICING_PLAN_SUBSCRIPTION_GROUP.CREATE'],
                    title: formatMessage({
                        defaultMessage: 'Add subscription group',
                        id: 'yzJoGX',
                    }),
                },
                components: {
                    panel: () => import('../views/Subscriptions/CreateSubscriptionGroup.vue'),
                },
            },
        ],
    },
    {
        path: '/subscriptions-groups',
        name: 'subscription-groups',
        meta: {
            name: formatMessage({ defaultMessage: 'subscription-groups', id: 'kRgPwQ' }),
            requiredPrivileges: ['PRICING_PLAN_SUBSCRIPTION_GROUP.VIEW'],
            title: formatMessage({ defaultMessage: 'Subscription groups', id: 'zX9emm' }),
        },
        component: () => import('../views/Subscriptions/SubscriptionOverview.vue'),
        props: { activeTab: 'subscription-groups' },
        children: [
            {
                path: `/subscriptions/add:customerId(${getResourceIdRegex('cust')})?`,
                name: 'subscription-groups-subscription-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'add subscription', id: 'ZlqQAP' }),
                    requiredPrivileges: ['PRICING_PLAN_SUBSCRIPTION.CREATE'],
                    title: formatMessage({ defaultMessage: 'Add subscription', id: '16BslC' }),
                },
                components: {
                    panel: () => import('../views/Subscriptions/CreateSubscription.vue'),
                },
            },
            {
                path: '/subscriptions-groups/add',
                name: 'subscription-groups-subscription-groups-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'add subscription group', id: 'uoDiyU' }),
                    requiredPrivileges: ['PRICING_PLAN_SUBSCRIPTION_GROUP.CREATE'],
                    title: formatMessage({
                        defaultMessage: 'Add subscription group',
                        id: 'yzJoGX',
                    }),
                },
                components: {
                    panel: () => import('../views/Subscriptions/CreateSubscriptionGroup.vue'),
                },
            },
        ],
    },
    {
        path: `/subscriptions/:subscriptionId(${getResourceIdRegex('ppsu')})`,
        name: 'subscriptions-detail',
        meta: {
            name: formatMessage({ defaultMessage: 'subscription', id: 'FDeR0i' }),
            title: formatMessage({ defaultMessage: 'Subscription details', id: 'hcxiJk' }),
        },
        component: () => import('../views/Subscriptions/SubscriptionDetail.vue'),
        children: [
            {
                path: `/subscriptions/:subscriptionId(${getResourceIdRegex('ppsu')})/edit`,
                name: 'subscriptions-edit',
                meta: {
                    name: formatMessage({ defaultMessage: 'subscription edit', id: 'cjNM1w' }),
                    requiredPrivileges: ['PRICING_PLAN_SUBSCRIPTION.VIEW'],
                    title: formatMessage({ defaultMessage: 'Edit subscription', id: 'BP56Ek' }),
                },
                components: {
                    panelInline: () => import('../views/Subscriptions/EditSubscription.vue'),
                },
            },
        ],
    },
    {
        path: '/settings/billing-entities',
        name: 'billing-entities',
        meta: {
            name: formatMessage({ defaultMessage: 'settings overview', id: 'd3BGJN' }),
            requiredPrivileges: ['BILLING_ENTITY.CREATE'],
            title: formatMessage({ defaultMessage: 'Billing entity settings', id: 'gNExzX' }),
        },
        component: () => import('../views/Settings/BillingEntities/Overview.vue'),
        children: [
            {
                path: `/settings/billing-entities/:billingEntityId(${getResourceIdRegex('bile')})`,
                name: 'billing-entity-detail',
                meta: {
                    name: formatMessage({ defaultMessage: 'Billing entity details', id: 'p4kqRd' }),
                    requiredPrivileges: ['BILLING_ENTITY.CREATE'],
                    title: formatMessage({
                        defaultMessage: 'Billing entity details',
                        id: 'p4kqRd',
                    }),
                },
                components: {
                    default: () => import('../views/Settings/BillingEntities/Overview.vue'),
                    panel: () =>
                        import('../views/Settings/BillingEntities/BillingEntityDetails.vue'),
                },
                props: {
                    panel: { isOpen: true, method: 'CREATE' },
                },
            },
            {
                path: '/settings/billing-entities/add',
                name: 'billing-entities-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'Create billing entity', id: 'kaA/cP' }),
                    requiredPrivileges: ['BILLING_ENTITY.CREATE'],
                    title: formatMessage({ defaultMessage: 'Create billing entity', id: 'kaA/cP' }),
                },
                components: {
                    default: () => import('../views/Settings/BillingEntities/Overview.vue'),
                    panel: () => import('../views/Settings/BillingEntities/Panel.vue'),
                },
                props: {
                    panel: { isOpen: true, method: 'CREATE' },
                },
            },
            {
                path: `/settings/billing-entities/:billingEntityId(${getResourceIdRegex(
                    'bile'
                )})/edit`,
                name: 'billing-entities-edit',
                meta: {
                    name: formatMessage({ defaultMessage: 'Edit billing entity', id: 'ae7yDX' }),
                    requiredPrivileges: ['BILLING_ENTITY.CREATE', 'BILLING_ENTITY.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Edit billing entity', id: 'ae7yDX' }),
                },
                components: {
                    default: () => import('../views/Settings/BillingEntities/Overview.vue'),
                    panel: () => import('../views/Settings/BillingEntities/Panel.vue'),
                },
                props: {
                    panel: { isOpen: true, method: 'UPDATE' },
                },
            },
            {
                path: `/settings/billing-entities/:billingEntityId(${getResourceIdRegex(
                    'bile'
                )})/payment-acceptors/add`,
                name: 'billing-entity-create-payment-acceptor',
                meta: {
                    name: formatMessage({
                        defaultMessage: 'payment acceptors create',
                        id: 'fgS0Gk',
                    }),
                    requiredPrivileges: ['PAYMENT_ACCEPTOR.CREATE'],
                    title: formatMessage({ defaultMessage: 'Add payment acceptor', id: 'DJz1ga' }),
                },
                components: {
                    default: () => import('../views/Settings/BillingEntities/Overview.vue'),
                    panel: () =>
                        import('../views/Settings/BillingEntities/PaymentAcceptors/Panel.vue'),
                },
                props: {
                    panel: { isOpen: true, method: 'CREATE' },
                },
            },
            {
                path: `/settings/billing-entities/:billingEntityId(${getResourceIdRegex(
                    'bile'
                )})/payment-acceptors/:paymentAcceptorId(${getResourceIdRegex('paya')})/edit`,
                name: 'billing-entity-update-payment-acceptor',
                meta: {
                    name: formatMessage({
                        defaultMessage: 'payment acceptors update',
                        id: 'iUbi2/',
                    }),
                    requiredPrivileges: ['PAYMENT_ACCEPTOR.VIEW', 'PAYMENT_ACCEPTOR.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Edit payment acceptor', id: 'QhCssj' }),
                },
                components: {
                    default: () => import('../views/Settings/BillingEntities/Overview.vue'),
                    panel: () =>
                        import('../views/Settings/BillingEntities/PaymentAcceptors/Panel.vue'),
                },
                props: {
                    panel: { isOpen: true, method: 'UPDATE' },
                },
            },
        ],
    },
    /**
     * Settings - Custom fields
     */
    {
        path: '/settings/custom-fields',
        name: 'settings-custom-fields',
        meta: {
            name: formatMessage({ defaultMessage: 'custom overview', id: 'wVX2Mc' }),
            requiredPrivileges: ['CUSTOM_FIELD.CREATE'],
            title: formatMessage({ defaultMessage: 'Custom field settings', id: 'e3ah52' }),
        },
        component: () => import('../views/Settings/CustomFields/Overview.vue'),
        children: [
            {
                path: '/settings/custom-fields/add',
                name: 'settings-custom-fields-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'custom fields', id: 'c9mzO4' }),
                    requiredPrivileges: ['CUSTOM_FIELD.CREATE'],
                    title: formatMessage({ defaultMessage: 'Add custom field', id: 'PflFGn' }),
                },
                components: {
                    panel: () => import('../views/Settings/CustomFields/Panel.vue'),
                },
                props: {
                    panel: { isOpen: true, mode: 'CREATE' },
                },
            },
            {
                path: `/settings/custom-fields/:id(${getResourceIdRegex('cusf')})/edit`,
                name: 'settings-custom-fields-edit',
                meta: {
                    name: formatMessage({ defaultMessage: 'custom fieldS edit', id: 'eMna4c' }),
                    requiredPrivileges: ['CUSTOM_FIELD.VIEW', 'CUSTOM_FIELD.UPDATE'],
                    title: formatMessage({ defaultMessage: 'Edit custom field', id: 'LMO1q1' }),
                },
                components: {
                    panel: () => import('../views/Settings/CustomFields/Panel.vue'),
                },
                props: { panel: { isOpen: true, mode: 'UPDATE' } },
            },
        ],
    },
    {
        path: '/settings/users',
        name: 'users',
        meta: {
            name: formatMessage({ defaultMessage: 'settings overview', id: 'd3BGJN' }),
            requiredPrivileges: ['USER.MORTAL.CREATE'],
            title: formatMessage({ defaultMessage: 'User settings', id: 'AfTuRr' }),
        },
        component: () => import('../views/Settings/Users/Users.vue'),
        children: [
            {
                path: '/settings/users/add',
                name: 'users-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'add user', id: 'OJkhz1' }),
                    requiredPrivileges: ['USER.MORTAL.CREATE'],
                    title: formatMessage({ defaultMessage: 'Add user', id: 'Dra4ET' }),
                },
                components: {
                    panelInline: () => import('../views/Settings/Users/CreateUser.vue'),
                },
                props: {
                    panel: {
                        isOpen: true,
                    },
                },
            },
            {
                path: `/settings/users/:id(${getResourceIdRegex('user')})`,
                name: 'user-details',
                meta: {
                    name: formatMessage({ defaultMessage: 'user details', id: 'oPvgXm' }),
                    requiredPrivileges: ['USER.MORTAL.VIEW'],
                    title: formatMessage({ defaultMessage: 'User details', id: '/uUIep' }),
                },
                components: {
                    panel: () => import('../views/Settings/Users/UserDetails.vue'),
                },
                props: {
                    panel: {
                        isOpen: true,
                    },
                },
            },
            {
                path: `/settings/users/:id(${getResourceIdRegex('user')})/edit`,
                name: 'user-edit',
                meta: {
                    name: formatMessage({ defaultMessage: 'user edit', id: 'jIeZT/' }),
                    requiredPrivileges: ['USER.MORTAL.VIEW'],
                    title: formatMessage({ defaultMessage: 'User edit', id: 'RAzF0L' }),
                },
                components: {
                    panelInline: () => import('../views/Settings/Users/EditUser.vue'),
                },
                props: {
                    panel: {
                        isOpen: true,
                    },
                },
            },
            {
                path: `/settings/users/:userId(${getResourceIdRegex(
                    'user'
                )})/:platId(${getResourceIdRegex('plat')})/assign-roles`,
                name: 'assign-roles',
                meta: {
                    name: formatMessage({ defaultMessage: 'assign roles', id: 'yxevH/' }),
                    requiredPrivileges: ['USER.MORTAL.UPDATE', 'ROLE.VIEW'],
                    title: formatMessage({ defaultMessage: 'Assign roles', id: '+SQ/s+' }),
                },
                components: {
                    panel: () => import('../views/Settings/Users/AssignRolesPanel.vue'),
                },
                props: {
                    panel: {
                        isOpen: true,
                    },
                },
            },
        ],
    },
    {
        path: '/settings/webhooks',
        name: 'webhooks',
        meta: {
            name: formatMessage({ defaultMessage: 'settings overview', id: 'd3BGJN' }),
            requiredPrivileges: ['WEBHOOK.VIEW'],
            title: formatMessage({ defaultMessage: 'Webhook settings', id: 'IJvULe' }),
        },
        component: () => import('../views/Settings/Webhooks/Webhooks.vue'),
        children: [
            {
                path: '/settings/webhooks/add',
                name: 'webhooks-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'webhooks', id: 'Tx/W41' }),
                    requiredPrivileges: ['WEBHOOK.CREATE'],
                    title: formatMessage({ defaultMessage: 'Add webhook', id: 'iHiM5+' }),
                },
                components: {
                    panel: () => import('../views/Settings/Webhooks/WebhooksPanel.vue'),
                },
                props: {
                    panel: {
                        isOpen: true,
                    },
                },
            },
        ],
    },
    {
        path: '/settings/branding',
        name: 'branding',
        meta: {
            name: formatMessage({ defaultMessage: 'settings overview', id: 'd3BGJN' }),
            requiredPrivileges: ['BRANDING.UPDATE'],
            title: formatMessage({ defaultMessage: 'Branding settings', id: 'f39eJy' }),
        },
        component: () => import('../views/Settings/Branding/Overview.vue'),
    },
    {
        path: '/settings/invoices',
        name: 'settings-invoices',
        meta: {
            name: formatMessage({ defaultMessage: 'settings invoices', id: 'yj9RaG' }),
            requiredPrivileges: ['INVOICE.DEFAULT_SETTINGS.EDIT'],
            title: formatMessage({ defaultMessage: 'Invoice settings', id: 't31HTz' }),
        },
        component: () => import('../views/Settings/Invoices/Overview.vue'),
    },
    {
        path: '/settings/api-keys',
        name: 'api-keys',
        meta: {
            name: formatMessage({ defaultMessage: 'settings api keys', id: '8360lt' }),
            requiredPrivileges: ['USER.API.CREATE'],
            title: formatMessage({ defaultMessage: 'API key settings', id: 'WqTwDZ' }),
        },
        component: () => import('../views/Settings/ApiKeys/ApiKeysOverview.vue'),
    },
    {
        path: '/settings/approval-policies',
        name: 'approval-policies',
        meta: {
            name: formatMessage({ defaultMessage: 'approval policies', id: 'Z4d8Kz' }),
            requiredPrivileges: ['APPROVAL_POLICY.CREATE'],
            title: formatMessage({ defaultMessage: 'Approval policies', id: 'W21An6' }),
        },
        component: () => import('../views/Settings/ApprovalPolicies/ApprovalPoliciesOverview.vue'),
    },
    {
        path: '/settings/alert-rules',
        name: 'alert-rules',
        meta: {
            name: formatMessage({ defaultMessage: 'settings overview', id: 'd3BGJN' }),
            requiredPrivileges: ['ALERT_RULE.CREATE'],
            title: formatMessage({ defaultMessage: 'Alert rules', id: 'wBypmY' }),
        },
        component: () => import('../views/Settings/AlertRules/AlertRules.vue'),
        children: [
            {
                path: '/settings/alert-rules/add',
                name: 'alert-rules-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'Alert rules create', id: 'X15tMf' }),
                    requiredPrivileges: ['ALERT_RULE.CREATE'],
                    title: formatMessage({ defaultMessage: 'Create alert rule', id: 'onzEcC' }),
                },
                components: {
                    default: () => import('../views/Settings/AlertRules/AlertRules.vue'),
                    panel: () => import('../views/Settings/AlertRules/CreateAlertRule.vue'),
                },
                props: {
                    panel: { isOpen: true },
                },
            },
        ],
    },
    {
        path: '/settings/integrations',
        name: 'integrations',
        meta: {
            name: formatMessage({ defaultMessage: 'settings overview', id: 'd3BGJN' }),
            requiredPrivileges: ['INTEGRATION.VIEW'],
            title: formatMessage({ defaultMessage: 'Integrations', id: '6cBDhe' }),
        },
        component: () => import('../views/Settings/Integrations/Integrations.vue'),
        children: [
            {
                path: '/settings/integrations/add',
                name: 'integrations-add',
                meta: {
                    name: formatMessage({ defaultMessage: 'integrations', id: '8ir9kY' }),
                    requiredPrivileges: ['INTEGRATION.CREATE'],
                    title: formatMessage({ defaultMessage: 'Add integration', id: 'e3eOFV' }),
                },
                components: {
                    default: () => import('../views/Settings/Integrations/Integrations.vue'),
                    panel: () => import('../views/Settings/Integrations/AddIntegration.vue'),
                },
                props: {
                    panel: {
                        isOpen: true,
                    },
                },
            },
            {
                path: '/settings/integrations/add/:integrationType',
                name: 'integrations-configure-integration',
                meta: {
                    name: formatMessage({ defaultMessage: 'integrations', id: '8ir9kY' }),
                    requiredPrivileges: ['INTEGRATION.CREATE'],
                    title: formatMessage({ defaultMessage: 'Configure integration', id: 'uhWu7S' }),
                },
                components: {
                    default: () => import('../views/Settings/Integrations/Integrations.vue'),
                    panel: () => import('../views/Settings/Integrations/IntegrationPanel.vue'),
                },
                props: {
                    panel: {
                        isOpen: true,
                    },
                },
                children: [
                    {
                        path: '/settings/integrations/add/:integrationType/:mandateName',
                        name: 'integrations-configure-integration-mandate',
                        meta: {
                            name: formatMessage({ defaultMessage: 'integrations', id: '8ir9kY' }),
                            requiredPrivileges: ['INTEGRATION.CREATE'],
                            title: formatMessage({ defaultMessage: 'Integrations', id: '6cBDhe' }),
                        },
                        components: {
                            default: () =>
                                import('../views/Settings/Integrations/Integrations.vue'),
                            panelInline: () =>
                                import(
                                    '../views/Settings/Integrations/components/IntegrationForm/Avalara/MandateForm.vue'
                                ),
                        },
                        props: {
                            panel: {
                                isOpen: true,
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        path: `/settings/integrations/:integrationType/:integrationId(${getResourceIdRegex(
            'intg'
        )})`,
        name: 'integration-detail',
        meta: {
            name: formatMessage({ defaultMessage: 'integration details', id: 'cDdrrE' }),
            requiredPrivileges: ['INTEGRATION.VIEW'],
            title: formatMessage({ defaultMessage: 'Integration details', id: 'kwcCG/' }),
        },
        component: () => import('../views/Settings/Integrations/IntegrationDetail.vue'),
    },
    {
        path: '/settings/auditing/:targetId?',
        name: 'auditing',
        meta: {
            name: formatMessage({ defaultMessage: 'audit log', id: 'btC50m' }),
            requiredPrivileges: ['AUDIT_RECORD.VIEW'],
            title: formatMessage({ defaultMessage: 'Audit log', id: 'ED9RG5' }),
        },
        component: () => import('../views/Settings/Auditing/AuditRecordsOverview.vue'),
        children: [
            {
                path: '/settings/auditing/:targetId?/details/:recordId',
                name: 'audit-record-details',
                meta: {
                    name: formatMessage({ defaultMessage: 'audit record details', id: 'UxKlog' }),
                    requiredPrivileges: ['AUDIT_RECORD.VIEW'],
                    title: formatMessage({ defaultMessage: 'Audit record details', id: 'mVrdsP' }),
                },
                props: { panel: { isOpen: true } },
                components: {
                    panel: () => import('../views/Settings/Auditing/AuditRecordDetails.vue'),
                },
            },
        ],
    },
    {
        path: '/settings/reprocessing',
        name: 'reprocessing',
        meta: {
            name: formatMessage({
                defaultMessage: 'reprocessing',
                id: 'QqOkg7',
            }),
            requiredPrivileges: ['REPROCESSING.REPROCESS.VIEW'],
            title: formatMessage({ defaultMessage: 'Reprocessing', id: 'qwa9k9' }),
        },
        component: () => import('../views/Settings/Reprocessing/ReprocessingOverview.vue'),
    },
    {
        path: '/settings/account',
        name: 'account',
        meta: {
            name: formatMessage({ defaultMessage: 'manage user account', id: 'BTBhZy' }),
            requiredPrivileges: ['USER.MORTAL.UPDATE'],
            title: formatMessage({ defaultMessage: 'Manage user account', id: 'g3qa/w' }),
        },
        component: () => import('../views/Settings/Account/ManageAccount.vue'),
    },
    {
        path: '/settings/platform',
        name: 'settings-platform',
        meta: {
            name: formatMessage({ defaultMessage: 'default platform settings', id: '9OFl9i' }),
            requiredPrivileges: ['PLATFORM.DEFAULT_SETTINGS.EDIT'],
            title: formatMessage({ defaultMessage: 'Default platform settings', id: 'Hp9Aqh' }),
        },
        component: () => import('../views/Settings/Platform/PlatformOverview.vue'),
    },
    {
        path: '/settings/platform/subscriptions',
        name: 'settings-platform-subscriptions',
        meta: {
            name: formatMessage({ defaultMessage: 'default subscriptions settings', id: 'omtIa1' }),
            requiredPrivileges: ['PRICING_PLAN_SUBSCRIPTION.DEFAULT_SETTINGS.EDIT'],
            title: formatMessage({ defaultMessage: 'Default subscription settings', id: 'yWVF4s' }),
        },
        props: { activeTab: 'subscriptions' },
        component: () => import('../views/Settings/Platform/PlatformOverview.vue'),
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('../views/Events/EventOverview.vue'),
        meta: {
            name: formatMessage({ defaultMessage: 'events', id: 'yi/uLq' }),
            requiredPrivileges: ['METER_DATA.INGEST'],
            title: formatMessage({ defaultMessage: 'Events', id: 'ZvKSfJ' }),
        },
        children: [
            {
                path: `/events/:meterId(${getResourceIdRegex('metr')})`,
                name: 'events-details',
                meta: {
                    name: formatMessage({ defaultMessage: 'event details', id: 'uvFfO7' }),
                    requiredPrivileges: ['METER_DATA.VIEW'],
                    title: formatMessage({ defaultMessage: 'Event details', id: 'DbjN+V' }),
                },
                components: {
                    default: () => import('../views/Events/EventDetail.vue'),
                },
            },
            {
                path: `/events/ingest/:meterId(${getResourceIdRegex('metr')})`,
                name: 'events-ingest-data',
                meta: {
                    name: formatMessage({ defaultMessage: 'data ingestion', id: 'pvqC93' }),
                    requiredPrivileges: ['METER_DATA.INGEST'],
                    title: formatMessage({ defaultMessage: 'Data ingestion', id: 'gdF2pA' }),
                },
                components: {
                    default: () => import('../views/Events/EventDetail.vue'),
                    panel: () => import('../views/Events/IngestData/IngestDataPanel.vue'),
                },
                props: { panel: { isOpen: true } },
            },
            {
                path: `/events/event-timeline/:meterId(${getResourceIdRegex(
                    'metr'
                )})/:meterDataId(${getResourceIdRegex('mdat')})`,
                name: 'events-timeline',
                meta: {
                    name: formatMessage({ defaultMessage: 'event timeline', id: 'OHHgmO' }),
                    requiredPrivileges: ['METER_DATA.EVENT_TRACE.VIEW'],
                    title: formatMessage({ defaultMessage: 'Event timeline', id: '0uF5CO' }),
                },
                components: {
                    default: () => import('../views/Events/EventDetail.vue'),
                    panel: () => import('../views/Events/EventTimeline.vue'),
                },
                props: { panel: { isOpen: true } },
            },
        ],
    },
    {
        path: '/insights/overview',
        name: 'insights-overview',
        meta: {
            name: formatMessage({ defaultMessage: 'insights overview', id: 'AC7jnr' }),
            title: formatMessage({ defaultMessage: 'Insights overview', id: 'kUFPus' }),
        },
        component: () => import('../views/Insights/InsightsView.vue'),
    },
    {
        path: '/insights/usage',
        name: 'insights-usage',
        meta: {
            name: formatMessage({ defaultMessage: 'insights overview', id: 'AC7jnr' }),
            title: formatMessage({ defaultMessage: 'Insights overview', id: 'kUFPus' }),
        },
        component: () => import('../views/Insights/InsightsView.vue'),
    },
    {
        path: '/insights/customers',
        name: 'insights-customers',
        meta: {
            name: formatMessage({ defaultMessage: 'insights overview', id: 'AC7jnr' }),
            title: formatMessage({ defaultMessage: 'Insights overview', id: 'kUFPus' }),
        },
        component: () => import('../views/Insights/InsightsView.vue'),
    },
    {
        path: '/insights/recognized-revenue',
        name: 'insights-recognized-revenue',
        meta: {
            name: formatMessage({ defaultMessage: 'insights overview', id: 'AC7jnr' }),
            title: formatMessage({ defaultMessage: 'Insights overview', id: 'kUFPus' }),
        },
        component: () => import('../views/Insights/InsightsView.vue'),
    },
    {
        path: '/insights/revenue-balances',
        name: 'insights-revenue-balances',
        meta: {
            name: formatMessage({ defaultMessage: 'insights overview', id: 'AC7jnr' }),
            title: formatMessage({ defaultMessage: 'Insights overview', id: 'kUFPus' }),
        },
        component: () => import('../views/Insights/InsightsView.vue'),
    },
    {
        path: '/insights/recurring-revenue',
        name: 'insights-recurring-revenue',
        meta: {
            name: formatMessage({ defaultMessage: 'insights overview', id: 'AC7jnr' }),
            title: formatMessage({ defaultMessage: 'Insights overview', id: 'kUFPus' }),
        },
        component: () => import('../views/Insights/InsightsView.vue'),
    },
    {
        path: '/developer-tools',
        name: 'developer-tools',
        meta: {
            name: formatMessage({ defaultMessage: 'Developer tools', id: 'g/w+Mb' }),
            title: formatMessage({ defaultMessage: 'Developer tools', id: 'g/w+Mb' }),
        },
        component: () => import('../views/DeveloperTools/Overview.vue'),
    },
    {
        path: '/quotes',
        name: 'quotes',
        meta: {
            name: formatMessage({ defaultMessage: 'quotes', id: 'ajTSV1' }),
            requiredPrivileges: ['QUOTE.VIEW'],
            title: formatMessage({ defaultMessage: 'Quotes', id: 'epJ5ty' }),
        },
        component: () => import('../views/Quotes/QuoteOverview.vue'),
    },
    {
        path: '/quotes/init',
        name: 'quotes-init',
        meta: {
            name: formatMessage({ defaultMessage: 'quotes', id: 'ajTSV1' }),
            requiredPrivileges: ['QUOTE.CREATE'],
            title: formatMessage({ defaultMessage: 'Create quote', id: 'A79NSV' }),
        },
        component: () => import('../views/Quotes/QuoteInit/QuoteInit.vue'),
    },
    {
        path: `/quotes/:quoteId(${getResourceIdRegex(
            'quot'
        )})/versions/:quoteVersionId(${getResourceIdRegex('quve')})?/add`,
        name: 'quotes-version-subscription-add',
        meta: {
            name: formatMessage({ defaultMessage: 'subscription settings', id: 'AIVhou' }),
            requiredPrivileges: ['QUOTE.VIEW', 'PRICING_PLAN_SUBSCRIPTION.CREATE'],
            title: formatMessage({ defaultMessage: 'Create quote', id: 'A79NSV' }),
        },
        component: () => import('../views/Quotes/CreateQuoteSubscription.vue'),
    },
    {
        path: `/quotes/:subscriptionId(${getResourceIdRegex('ppsu')})`,
        name: 'quotes-subscription',
        meta: {
            name: formatMessage({ defaultMessage: 'quote', id: 'HhLp57' }),
            requiredPrivileges: ['QUOTE.VIEW', 'QUOTE.PRICING_PLAN_SUBSCRIPTION.VIEW'],
            title: formatMessage({ defaultMessage: 'Quote', id: 'atzUcB' }),
        },
        component: () => import('../views/Quotes/QuoteView.vue'),
        children: [
            {
                path: `/quotes/:subscriptionId(${getResourceIdRegex('ppsu')})/notes`,
                name: 'quotes-subscription-notes',
                meta: {
                    name: formatMessage({ defaultMessage: 'quote notes', id: 'MhEMLi' }),
                    title: formatMessage({ defaultMessage: 'Quote notes', id: 'jpjcay' }),
                },
                props: { panel: { isOpen: true, action: 'create' } },
                components: {
                    panelInline: () => import('../views/Quotes/QuoteNotesPanel.vue'),
                },
            },
        ],
    },
    {
        path: `/quotes/:subscriptionId(${getResourceIdRegex('ppsu')})/edit`,
        name: 'quotes-subscription-edit',
        meta: {
            name: formatMessage({ defaultMessage: 'edit quote', id: 'OQpHrr' }),
            requiredPrivileges: [
                'QUOTE.PRICING_PLAN_SUBSCRIPTION.VIEW',
                'QUOTE.PRICING_PLAN_SUBSCRIPTION.UPDATE',
            ],
            title: formatMessage({ defaultMessage: 'Edit subscription', id: 'BP56Ek' }),
        },
        component: () => import('../views/Quotes/UpdateQuoteSubscription.vue'),
    },
    {
        path: '/alerts',
        name: 'alerts',
        meta: {
            name: formatMessage({ defaultMessage: 'Alerts', id: '9zub+u' }),
            requiredPrivileges: ['ALERT.VIEW'],
            title: formatMessage({ defaultMessage: 'Alerts', id: '9zub+u' }),
        },
        component: () => import('../views/Alerts/AlertOverview.vue'),
    },
    {
        path: '/files',
        name: 'files',
        meta: {
            name: formatMessage({ defaultMessage: 'file overview', id: 'LYoWC2' }),
            requiredPrivileges: ['FILE.VIEW'],
            title: formatMessage({ defaultMessage: 'Files', id: 'm4vqJl' }),
        },
        component: () => import('../views/Files/FileOverview.vue'),
    },
    {
        path: `/files/:id(${getResourceIdRegex('file')})`,
        name: 'files-detail',
        meta: {
            name: formatMessage({ defaultMessage: 'file', id: 'vwxMsy' }),
            requiredPrivileges: ['FILE.VIEW'],
            title: formatMessage({ defaultMessage: 'File details', id: 'zCOWUF' }),
        },
        component: () => import('../views/Files/FileDetail.vue'),
    },
    {
        path: '/files/upload',
        name: 'files-upload',
        meta: {
            name: formatMessage({ defaultMessage: 'file upload', id: 'to9JC9' }),
            requiredPrivileges: ['FILE.METER_DATA.CREATE'],
            title: formatMessage({ defaultMessage: 'File upload', id: 'hEl6BL' }),
        },
        props: {
            panel: { isOpen: true },
        },
        components: {
            default: () => import('../views/Files/FileOverview.vue'),
            panel: () => import('../views/Files/UploadFilePanel.vue'),
        },
    },
    {
        path: '/restricted-access',
        name: 'restricted-access',
        meta: {
            name: formatMessage({ defaultMessage: 'Restricted access', id: 'YsoCdO' }),
            title: formatMessage({ defaultMessage: 'Restricted access', id: 'YsoCdO' }),
        },
        component: () => import('../views/RestrictedAccess.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        meta: {
            name: formatMessage({ defaultMessage: 'Page not found', id: 'QRccCM' }),
            title: formatMessage({ defaultMessage: 'Page not found', id: 'QRccCM' }),
        },
        component: () => import('../views/PageNotFoundView.vue'),
    },
];
