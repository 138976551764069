<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useIntl } from 'vue-intl';
import { Typography } from '@solvimon/ui';
import { useCriticalError } from './useCriticalError';
import { ErrorModal } from '@/components/ErrorModal';
import { IconButton } from '@/components/IconButton';
import { useClipboard, useSelectText } from '@/composables';
import { Button } from '@/components/Button';

const { errors, reset } = useCriticalError();
const { formatMessage } = useIntl();
const { select, unselect } = useSelectText();

const requestIdRef = ref();
const activeErrorIndex = ref(0);
const copiedText = ref();

const [copy] = useClipboard();

const handleClose = () => {
    reset();
    activeErrorIndex.value = 0;
};

const handleCopy = () => {
    copy(error.value.requestId).then(() => {
        select(requestIdRef.value);
        copiedText.value = formatMessage({ defaultMessage: 'Copied!', id: '0oL1zz' });

        setTimeout(() => {
            copiedText.value = undefined;
        }, 2000);
    });
};

const handleShowNext = () => {
    if (activeErrorIndex.value >= errors.value.length - 1) return;
    activeErrorIndex.value = activeErrorIndex.value + 1;
};

const handleShowPrevious = () => {
    if (activeErrorIndex.value <= 0) return;
    activeErrorIndex.value = activeErrorIndex.value - 1;
};

const hasPreviousErrors = computed(() => activeErrorIndex.value >= 1);

const hasNextErrors = computed(() => activeErrorIndex.value < errors.value.length - 1);

const error = computed(() => errors.value[activeErrorIndex.value]);

watch(
    () => error.value,
    () => {
        unselect();
    }
);
</script>

<template>
    <ErrorModal :title="error?.title" :show-modal="errors.length > 0" blurred @close="handleClose">
        <template #body>
            <div class="text-center">
                {{
                    error?.message ??
                    $t({
                        defaultMessage:
                            'Try again or reach out for additional support and mention the requestId displayed below.',
                        id: 'Yg0CIh',
                    })
                }}
            </div>

            <div class="bg-gray-50 border-y -ml-6 -mr-6 px-6 py-2 mt-6">
                <div
                    v-if="errors.length > 1"
                    class="flex justify-between items-center border-b pb-2 mb-2"
                >
                    <Typography variant="caps-heading">{{
                        $t(
                            {
                                defaultMessage: 'Error {current} of {total_number_of_errors}',
                                id: 'adwiC6',
                            },
                            { current: activeErrorIndex + 1, total_number_of_errors: errors.length }
                        )
                    }}</Typography>
                    <div class="inline-flex gap-1">
                        <IconButton
                            :disabled="!hasPreviousErrors"
                            square
                            pill
                            icon="chevron_left"
                            size="sm"
                            color="gray"
                            variant="ghost"
                            @click.stop="handleShowPrevious"
                        />
                        <IconButton
                            :disabled="!hasNextErrors"
                            square
                            pill
                            icon="chevron_right"
                            size="sm"
                            color="gray"
                            variant="ghost"
                            @click.stop="handleShowNext"
                        />
                    </div>
                </div>
                <div class="text-center">
                    <Typography tag="span" variant="body-xs" mono>
                        <span ref="requestIdRef">{{ error?.requestId }}</span>
                    </Typography>
                </div>
            </div>
        </template>
        <template #buttons>
            <Button :icon-prefix="copiedText ? 'check' : 'copy_all'" @click.stop="handleCopy">
                {{ copiedText ?? $t({ defaultMessage: 'Copy request ID', id: 'TahB+L' }) }}
            </Button>
        </template>
    </ErrorModal>
</template>
