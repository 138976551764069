export const SHOW_RESOURCE_IDS_KEY = 'showResourceIds';
export const SHOW_RESOURCE_REFERENCE_KEY = 'showResourceReferences';
export const SHOW_NON_BILLED_PRICINGS_KEY = 'showNonBilledPricings';
export const NUMBER_FORMAT_KEY = 'numberFormat';
export const SIDEBAR_STORAGE_KEY = 'is-sidebar-open';

export const showResourceIdsSetting: string | null = localStorage.getItem(SHOW_RESOURCE_IDS_KEY);
export const showResourceReferencesSetting: string | null = localStorage.getItem(
    SHOW_RESOURCE_REFERENCE_KEY
);
export const showNonBilledPricingsSetting: string | null = localStorage.getItem(
    SHOW_NON_BILLED_PRICINGS_KEY
);
export const numberFormatSetting: string | null = localStorage.getItem(NUMBER_FORMAT_KEY);
export const getSidebarStorageSetting: () => string | null = () =>
    localStorage.getItem(SIDEBAR_STORAGE_KEY);
