<script setup lang="ts">
import { onMounted } from 'vue';
import type { PortalName } from './Portal.types';

const props = defineProps<{
    /**
     * The portal to target.
     */
    to?: PortalName;
    /**
     * The name of the portal.
     */
    name?: PortalName;
}>();

onMounted(() => {
    if (props.name || props.to) return;

    throw new Error('Define either the `to` or the `name` prop.');
});
</script>

<template>
    <div v-if="name" :id="`portal-${name}`" />
    <Teleport v-else-if="to" :to="`#portal-${to}`"><slot /></Teleport>
</template>
