/**
 * Util to construct params in a way so that arrays are properly understood by the API.
 * Normally we would use URLSearchParams.toString for this but that does not export the array format the API expects.
 */
export const formatQueryParams = (params: Record<string, string | string[] | null>): string => {
    const queryParams = new URLSearchParams();

    for (const key in params) {
        const value = params[key];

        if (value !== null) {
            if (Array.isArray(value)) {
                value.forEach((item) => queryParams.append(`${key}[]`, item));
            } else {
                if (value !== '') {
                    queryParams.append(key, value);
                }
            }
        }
    }

    return queryParams.toString();
};
