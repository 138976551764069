import type {
    PricingPlanSubscription,
    PricingPlanSubscriptionExpanded,
    PricingPlanSubscriptionExpandedListItem,
} from '@solvimon/types';
import { handleResponse, getFormattedDateRangeFilter } from './lib';
import type { OrderByOption } from './subscriptions.types';
import { del, get, patch, post } from '@/callcenter';
import type { CancelSubscriptionType, OrderDirection } from '@/types';
import type { ApiSuccessCollectionResponse, ApiSuccessResponse } from '@/callcenter/types';
import { serializeQueryParams, withExpand, withPagination } from '@/utils/api';

const ENDPOINT = `${import.meta.env.CONFIG_URL}/pricing-plan-subscriptions`;
const PAGE_SIZE = 15;

export function getSubscription(
    id: PricingPlanSubscription['id'],
    expanded?: false
): Promise<ApiSuccessResponse<PricingPlanSubscription>>;
export function getSubscription(
    id: PricingPlanSubscription['id'],
    expanded: true
): Promise<ApiSuccessResponse<PricingPlanSubscriptionExpanded>>;
/**
 * Get a single subscription.
 */

export function getSubscription(
    id: PricingPlanSubscription['id']
): Promise<PricingPlanSubscription>;
export function getSubscription(
    id: PricingPlanSubscription['id'],
    expanded: true
): Promise<PricingPlanSubscriptionExpanded>;
export function getSubscription(id: PricingPlanSubscription['id'], expand = false) {
    const queryParams = withExpand({
        expandParams: [
            'customer_id',
            'billing_entity_id',
            'payment_acceptor_ids',
            'forward_customer',
            'pricing_plan_subscription_group_id',
        ],
        expand,
    });

    return handleResponse<PricingPlanSubscription>({
        request: get(`${ENDPOINT}/${id}${serializeQueryParams(queryParams)}`),
    });
}

interface GetSubscriptionOptions {
    page: number;
    orderBy?: OrderByOption;
    orderDirection?: OrderDirection;
    customerId?: string;
    status?: string;
    group?: string;
    startAtFrom?: string;
    startAtTo?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    type?: PricingPlanSubscription['type'];
    variant?: PricingPlanSubscription['variant'];
    pageSize?: number;
}

export function getSubscriptions(
    options: GetSubscriptionOptions,
    expanded?: false
): Promise<ApiSuccessCollectionResponse<PricingPlanSubscription>>;
export function getSubscriptions(
    options: GetSubscriptionOptions,
    expanded: true
): Promise<ApiSuccessCollectionResponse<PricingPlanSubscriptionExpandedListItem>>;

/**
 * Get a list of all pricing plan subscriptions.
 */
export function getSubscriptions(
    {
        page,
        orderBy,
        orderDirection,
        customerId,
        status,
        group,
        startAtFrom,
        startAtTo,
        createdAtFrom,
        createdAtTo,
        type = 'BILLING',
        variant,
        pageSize,
    }: GetSubscriptionOptions,
    expand = false
) {
    let queryParams = withExpand({
        initialParams: {
            customer_id: customerId ?? null,
            status: status ?? null,
            pricing_plan_subscription_group_id: group ?? null,
            type: type ?? null,
            variant: variant ?? null,
        },
        expandParams: ['customer_id', 'pricing_plan_subscription_group_id'],
        expand,
    });
    queryParams = withPagination(queryParams, {
        page,
        pageSize: pageSize ?? PAGE_SIZE,
        orderBy,
        orderDirection,
    });

    if (startAtFrom || startAtTo) {
        Object.assign(queryParams, {
            start_at: getFormattedDateRangeFilter(startAtFrom, startAtTo),
        });
    }

    if (createdAtFrom || createdAtTo) {
        Object.assign(queryParams, {
            created_at: getFormattedDateRangeFilter(createdAtFrom, createdAtTo),
        });
    }

    return handleResponse<PricingPlanSubscription>({
        request: get(`${ENDPOINT}${serializeQueryParams(queryParams)}`),
        isCollection: true,
    });
}

/**
 * Create a new subscription.
 */
export const createSubscription = (subscription: Partial<PricingPlanSubscription>) => {
    const payload = {
        ...subscription,
        type: subscription.type ?? 'BILLING',
        reference:
            subscription.reference && subscription.reference !== ''
                ? subscription.reference
                : `subscription_${new Date().getTime()}`,
    };

    return handleResponse<PricingPlanSubscription>({
        request: post(ENDPOINT, payload),
    });
};

/**
 * Update a subscription.
 */
export const updateSubscription = (
    id: PricingPlanSubscription['id'],
    subscription: Partial<PricingPlanSubscription>
) => {
    return handleResponse<PricingPlanSubscription>({
        request: patch(`${ENDPOINT}/${id}`, subscription),
    });
};

/**
 * Delete a single subscription
 */
export const deleteSubscription = (id: PricingPlanSubscription['id']) => {
    return handleResponse({
        request: del(`${ENDPOINT}/${id}`),
    });
};

/**
 * Dublicate a single subscription
 */
export const dublicateSubscription = (id: PricingPlanSubscription['id']) => {
    return handleResponse({
        request: post(`${ENDPOINT}/${id}/copy`),
    });
};

/**
 * Void a subscription.
 */
export const voidSubscription = (id: PricingPlanSubscription['id']) => {
    return handleResponse<PricingPlanSubscription>({
        request: post(`${ENDPOINT}/${id}/void`),
    });
};

/**
 * Void a subscription.
 */
export const cancelSubscription = (
    id: PricingPlanSubscription['id'],
    data: { type: CancelSubscriptionType; cancel_at?: string }
) => {
    return handleResponse<PricingPlanSubscription>({
        request: post(`${ENDPOINT}/${id}/cancel`, data),
    });
};
