import { betaConfig } from './config.beta';
import { developmentConfig } from './config.dev';
import { productionConfig } from './config.live';
import { sharedConfig } from './config.shared';
import { testConfig } from './config.test';
import type { Config, SharedConfig } from './types';

export const getConfig = (environment: string): SharedConfig & Config => {
    switch (environment) {
        case 'LIVE':
            return { ...sharedConfig, ...productionConfig };
        case 'TEST':
            return { ...sharedConfig, ...testConfig };
        case 'BETA':
            return { ...sharedConfig, ...betaConfig };
        case 'DEV':
        default:
            return { ...sharedConfig, ...developmentConfig };
    }
};
