import 'tailwindcss/tailwind.css';
import '@/assets/tailwind.css';
import '@/assets/fonts.css';
import '@solvimon/tailwind-config/tailwind.css';

import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import type { Router } from 'vue-router';
import VueHotjar from 'vue-hotjar-next';
import { VueQueryPlugin } from 'vue-query';
import App from './App.vue';
import router from './router';
import config from './config';
import { queryClient } from './utils/vueQuery';
import { useApp } from '@/composables';
import { intlPlugin, rbacPlugin } from '@/plugins';

declare module 'pinia' {
    export interface PiniaCustomProperties {
        router: Router;
    }
}

const app = createApp(App);
const pinia = createPinia();

// make the router available to the stores
pinia.use(({ store }) => {
    store.router = markRaw(router);
});

app.use(VueHotjar, {
    id: 3514562,
    isProduction: ['test.desk.solvimon.com', 'desk.solvimon.com'].includes(
        document.location.hostname
    ),
    snippetVersion: 6,
});

app.use(pinia);
app.use(router);
app.use(intlPlugin);
app.use(rbacPlugin);
app.use(VueQueryPlugin, { queryClient });
app.mount('#app');

const appInfo = useApp();

async function lazyLoadSentry() {
    const Sentry = await import('@sentry/vue');

    Sentry.init({
        dsn: import.meta.env.SENTRY_DSN,
        app,
        environment: config.environment,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        replaysOnErrorSampleRate: 1,
        enabled: import.meta.env.SENTRY_ENABLED === 'true',
        beforeSend(event) {
            // we should never send the username to third parties like Sentry; so sending the domain only here:
            const userDomain = appInfo.userInfo.value?.username?.match(/(?<=@)[^.]+(?=\.)/g);
            const selectedAccountGroup = appInfo.userInfo.value?.account_groups?.find((group) =>
                group.account_memberships.find(
                    (membership) => membership.account_id === appInfo.platformId.value
                )
            );

            Sentry.setTag('userDomain', userDomain ? userDomain[0] : undefined);
            Sentry.setTag('platformReference', selectedAccountGroup?.account_group.reference);

            event.extra = {
                ...event.extra,
                'User domain': userDomain ? userDomain[0] : undefined,
                'Platform reference': selectedAccountGroup?.account_group.reference,
            };

            return { ...event };
        },
    });
}

lazyLoadSentry();
