import type { Config } from './types';

export const testConfig: Config = {
    apiUrl: 'https://test.api.solvimon.com',
    environment: 'TEST',
    integrations: {
        embeddable: {
            baseUrl: 'https://api.eu.embeddable.com/',
        },
    },
    reporting: {
        csp: {
            endpoint: '/reporting/csp',
            reportOnly: true,
        },
        loginTracking: {
            endpoint:
                'https://quiet-paper-5100.tines.com/webhook/b855bbb9be6a7c32208fb5e4e8477426/1dba4d72110ee5adc928e16b18b50a3e',
        },
    },
};
