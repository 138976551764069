import { useTitle } from '@vueuse/core';
import { watch } from 'vue';
import { useRoute } from 'vue-router';

/**
 * Returns the page title to fill the HTML <title> tag with.
 * If an argument given returns 'Argument here | Solvimon Desk'.
 * If no argument was given return 'Solvimon Desk'.
 */
export default (pageTitle?: string) => {
    const route = useRoute();
    const titleRef = useTitle();

    watch(
        () => route.meta?.title,
        (title) => {
            titleRef.value = [pageTitle ?? title, 'Solvimon Desk']
                .filter((item) => !!item)
                .join(' | ');
        }
    );
};
