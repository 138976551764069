export const animationClass = {
    default: 'animate-[shrink-width_3000ms_linear_forwards]',
    slow: 'animate-[shrink-width_6000ms_linear_forwards]',
};

export const progressBarColor = {
    success: {
        base: 'bg-green-50',
        bar: 'bg-green-200',
    },
    warning: {
        base: 'bg-orange-50',
        bar: 'bg-orange-200',
    },
    danger: {
        base: 'bg-red-50',
        bar: 'bg-red-200',
    },
    empty: {
        base: 'bg-gray-50',
        bar: 'bg-gray-200',
    },
};
