import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import createActivityDetector from 'activity-detector';

const idle = ref<boolean>(false);

const detector = ref(
    createActivityDetector({
        timeToIdle: 60 * 1000 * 4, // 4 minutes
        inactivityEvents: [], // Disable default inactivity event on blur
    })
);

export default () => {
    onBeforeMount(() => {
        detector.value.on('idle', () => {
            idle.value = true;
        });

        detector.value.on('active', () => {
            idle.value = false;
        });
    });

    onBeforeUnmount(() => {
        detector.value.stop();
    });

    return { idle };
};
