import { getDefaultHeaders, getErrorTrackingParams, handleError, isApiErrorResponse } from './lib';
import type { ApiErrorResponse } from './types';
import hasAccessToken from '@/utils/hasAccessToken';
import { Headers } from '@/constants';
import { trackSentryException } from '@/utils/errorTracking';

export default async <T>(endpoint: string) =>
    new Promise<T>((resolve, reject) =>
        hasAccessToken().then((result) => {
            if (!result) return;

            fetch(endpoint, {
                method: 'DELETE',
                headers: getDefaultHeaders(),
                credentials: 'include',
            })
                .then(async (response) => {
                    const json = await response.json().catch((error) => {
                        // Log to console, to make debugging easier. Also for back-end devs
                        // eslint-disable-next-line no-console
                        console.error(error);
                        trackSentryException(error, { Message: 'Failed parsing JSON from api' });

                        throw {
                            hasError: true,
                            statusCode: response.status,
                            requestId: response.headers.get(Headers.X_REQUEST_ID),
                        };
                    });

                    if (!response.ok) {
                        reject({
                            hasError: true,
                            statusCode: response.status,
                            message: json?.message,
                            requestId: response.headers.get(Headers.X_REQUEST_ID),
                            field: json?.field,
                        });
                    }

                    resolve(json);
                })
                .catch(async (error: ApiErrorResponse | unknown) => {
                    trackSentryException(
                        isApiErrorResponse(error)
                            ? new Error(`DELETE: ${endpoint} - ${error.message}`)
                            : new Error(`DELETE: ${endpoint}`),
                        { Error: error, ...getErrorTrackingParams(error) }
                    );

                    await handleError({
                        message: isApiErrorResponse(error) ? error.message : '',
                        statusCode: isApiErrorResponse(error) ? error.statusCode : undefined,
                        requestId: isApiErrorResponse(error) ? error.requestId : undefined,
                    });

                    return Promise.reject(error);
                });
        })
    );
