import type { SharedConfig } from './types';

export const sharedConfig: SharedConfig = {
    host: {
        DEV: 'localhost',
        BETA: 'desk.beta-solvimon.com',
        TEST: 'test.desk.solvimon.com',
        LIVE: 'desk.solvimon.com',
    },
};
