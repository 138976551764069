const DEFAULT_Z_INDEX = 60;
const Z_INDEX_TRACKING_CLASS = 'z-index-tracker';

/**
 * This composable looks for the element with the highest z-index and will return
 * this z-index + 1. That way, we can make sure page filling components can be shown
 * on top.
 */
export default () => {
    const getZIndex = () => {
        const elements = document.querySelectorAll(`.${Z_INDEX_TRACKING_CLASS}`);

        let zIndex = DEFAULT_Z_INDEX;

        (elements ?? []).forEach((element) => {
            const result = window.getComputedStyle(element).getPropertyValue('z-index');

            if (result && +result >= zIndex) {
                zIndex = +result + 1;
            }
        });

        return zIndex;
    };

    return {
        defaultZIndex: DEFAULT_Z_INDEX,
        getZIndex,
        trackingClass: Z_INDEX_TRACKING_CLASS,
    };
};
