<script setup lang="ts">
import { ref, watch, nextTick } from 'vue';
import { IconTitle } from '@solvimon/ui';
import type { ConfirmationModalEmits, ConfirmationModalProps } from './ConfirmationModal.types';
import { Modal } from '@/components/Modal';
import { Button } from '@/components/Button';

const props = withDefaults(defineProps<ConfirmationModalProps>(), {
    hasIcon: true,
    confirmButtonColor: 'red',
    size: 'sm',
    stacked: true,
});

defineEmits<ConfirmationModalEmits>();

const confirmButtonRef = ref();

watch(
    () => props.showModal,
    (value) => {
        if (value) {
            nextTick(() => {
                confirmButtonRef.value.$el.focus();
            });
        }
    }
);
</script>

<template>
    <Modal :show-modal="showModal" :size="size" @close="$emit('cancel')">
        <template #header>
            <IconTitle
                :title="title"
                :sub-title="subTitle"
                :icon="icon ?? (hasIcon ? 'warning' : undefined)"
                variant="warning"
                :stacked="stacked"
            />
        </template>
        <template #description>
            <slot name="description" />
        </template>
        <template #body>
            <slot name="body" />
        </template>
        <template #footer>
            <div class="flex gap-2 flex-col">
                <Button
                    ref="confirmButtonRef"
                    :color="confirmButtonColor"
                    autofocus
                    :loading="isPending"
                    :disabled="isPending"
                    loading-position="overlay"
                    data-testid="confirm-modal-confirm-button"
                    :form="submitButtonForm"
                    @click="submitButtonForm ? undefined : $emit('confirm')"
                >
                    <slot name="confirm-button-text">{{
                        i18n?.confirmButtonText ?? 'Confirm'
                    }}</slot>
                </Button>
                <Button color="gray" variant="outline" @click="$emit('cancel')">
                    <slot name="cancel-button-text">{{ i18n?.cancelButtonText ?? 'Cancel' }}</slot>
                </Button>
            </div>
        </template>
    </Modal>
</template>
