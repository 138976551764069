<script setup lang="ts">
import { Typography } from '@solvimon/ui';
import { useBlockingErrorModal } from './useBlockingErrorModal';
import { ErrorModal } from '@/components/ErrorModal';

const { error, close } = useBlockingErrorModal();
</script>

<template>
    <ErrorModal
        :title="$t({ defaultMessage: 'Something went wrong', id: 'JqiqNj' })"
        :show-modal="!!error"
        blurred
        @close="close"
    >
        <template #body>
            <Typography>
                {{ error }}
            </Typography>
        </template>
    </ErrorModal>
</template>
