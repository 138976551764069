import { computed } from 'vue';
import type { Environment, AccountGroupExpanded } from '@solvimon/types';
import { useApp } from '@/composables';
import useEnvironment from '@/composables/useEnvironment';
import { useRawIntl } from '@/plugins';
import { $ResetPinia } from '@/stores/utils/reset';
import type { Platform } from '@/services/platforms.types';

export default () => {
    const app = useApp();
    const { formatMessage } = useRawIntl();
    const { redirectToEnvironment } = useEnvironment();

    const accountGroups = computed(() => {
        return app.userInfo.value?.account_groups.map((accountGroup) => {
            // sorted so test button always comes left and live button always right in the UI
            accountGroup.account_memberships = accountGroup.account_memberships.sort((a, b) => {
                if (a.account.environment === 'TEST' && b.account.environment !== 'TEST') {
                    return -1; // 'TEST' comes before 'LIVE'
                } else if (a.account.environment !== 'TEST' && b.account.environment === 'TEST') {
                    return 1; // 'TEST' comes after 'LIVE'
                }
                return 0; // No change in order
            });
            return accountGroup;
        });
    });

    const selectedAccountGroup = computed(() =>
        (accountGroups.value ?? []).find((group) =>
            group.account_memberships.find(
                (membership) => membership.account_id === app.platformId.value
            )
        )
    );

    const getPlatformNameFromAccountGroup = (accountGroup?: AccountGroupExpanded) =>
        accountGroup?.account_group.description ??
        accountGroup?.account_group.reference ??
        formatMessage({ defaultMessage: 'Not set', id: 'p5LNtB' });

    const switchPlatform = (platformId: Platform['id'], environment: Environment) => {
        $ResetPinia();

        redirectToEnvironment('/overview', environment, platformId);
    };

    return {
        accountGroups,
        getPlatformNameFromAccountGroup,
        selectedAccountGroup,
        switchPlatform,
    };
};
