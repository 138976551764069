<script setup lang="ts">
import Toast from '@/components/Toaster/Toast.vue';
import { useNotifications } from '@/composables';

const { getItemKey, queue } = useNotifications();
</script>

<template>
    <div class="fixed right-4 bottom-4 z-80 overflow-hidden flex flex-col-reverse gap-2">
        <TransitionGroup
            enter-active-class="duration-300 ease-out transform-gpu"
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="duration-300 ease-in transform-gpu"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
            <Toast
                v-for="item in queue"
                :key="getItemKey(item)"
                :type="item.type"
                :message="item.message"
                :cta="item.cta"
            />
        </TransitionGroup>
    </div>
</template>
