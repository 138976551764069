import { computed, ref } from 'vue';
import type { Currency, Feature, Account, UserInfoExpanded } from '@solvimon/types';
import {
    NUMBER_FORMAT_KEY,
    SHOW_RESOURCE_IDS_KEY,
    SHOW_RESOURCE_REFERENCE_KEY,
    SHOW_NON_BILLED_PRICINGS_KEY,
    numberFormatSetting,
    showResourceIdsSetting,
    showResourceReferencesSetting,
    showNonBilledPricingsSetting,
} from './useLocalStorageData';
import { usePlatformCookie } from './';
import type { Platform, PlatformEntitlement } from '@/services/platforms.types';
import { getPlatform, getPlatformCurrencies, getPlatformEntitlements } from '@/services/platforms';
import { getUserInfoExpanded } from '@/services/identity';
import config from '@/config';

const currencies = ref<Currency[]>([]);
const features = ref<Feature['id'][]>([]);
const entitlements = ref<PlatformEntitlement[]>();
const hasPlatformDefaultSubscriptionSettings = computed(
    () => !!platform.value?.default_pricing_plan_subscription_settings?.billing_entity_id
);
const permissions = computed(() => {
    return {
        alerts: !!entitlements.value?.find(
            (entitlement) => entitlement.feature_reference === 'feat_DONT-CHANGE_alerts'
        )?.switch,
        quotes: !!entitlements.value?.find(
            (entitlement) => entitlement.feature_reference === 'feat_DONT-CHANGE_quotes'
        )?.switch,
        insights: !!entitlements.value?.find(
            (entitlement) => entitlement.feature_reference === 'feat_DONT-CHANGE_insights'
        )?.switch,
        revenueRecognition: !!entitlements.value?.find(
            (entitlement) =>
                entitlement.feature_reference === 'feat_DONT-CHANGE_revenue-recognition'
        )?.switch,
        deferredRevenue: !!entitlements.value?.find(
            (entitlement) => entitlement.feature_reference === 'feat_DONT-CHANGE_deferred-revenue'
        )?.switch,
    };
});
const isEmbedded = ref(false);
const showResourceIds = ref(showResourceIdsSetting ? showResourceIdsSetting === 'true' : false);
const showResourceReferences = ref();
const showNonBilledPricings = ref(showNonBilledPricingsSetting === 'false' ? false : true);

// Not showing resource references should be opt-out, so set it true by default:
if (showResourceReferencesSetting === null || showResourceReferencesSetting === 'true') {
    showResourceReferences.value = true;
} else if (showResourceReferencesSetting === 'false') {
    showResourceReferences.value = false;
}

const numberFormat = ref(numberFormatSetting ?? 'nl-NL');

const userInfo = ref<UserInfoExpanded>();
const showDevTools = ref(false);
const platform = ref<Platform>();
const platformId = ref<Platform['id'] | undefined>();

export default () => {
    const platformCookie = usePlatformCookie();

    const loadCurrencies = async (platformId: Platform['id']): Promise<Currency[]> => {
        const response = await getPlatformCurrencies(platformId);
        currencies.value = response;
        return response;
    };

    const loadEntitlements = async (platformId: Platform['id']) => {
        try {
            const response = await getPlatformEntitlements(platformId);
            entitlements.value = response.entitlements;
        } catch {
            // Catch so app still works when fetching entitlements fails
        }
    };

    const loadPlatform = async (platformId: Platform['id']): Promise<Platform> => {
        const response = await getPlatform(platformId);
        platform.value = response;
        return response;
    };

    const getAccountGroupByPlatformId = ({ platformId }: { platformId: Platform['id'] }) => {
        if (!userInfo.value?.account_groups) {
            return undefined;
        }

        return userInfo.value.account_groups.find((accountGroup) =>
            accountGroup.account_memberships.find(
                (membership) => membership.account_id === platformId
            )
        );
    };

    const getAccountByPlatformId = ({
        platformId,
    }: {
        platformId: Platform['id'];
    }): Account | undefined => {
        const accountGroup = getAccountGroupByPlatformId({ platformId });

        if (!accountGroup) {
            return undefined;
        }

        return accountGroup.account_memberships.find(
            (membership) => membership.account_id === platformId
        )?.account;
    };

    const loadUserInfo = async (platformId?: Platform['id']): Promise<UserInfoExpanded> =>
        new Promise((resolve, reject) => {
            getUserInfoExpanded(platformId)
                .then((response) => {
                    userInfo.value = response;
                    return resolve(response);
                })
                .catch((err) => {
                    if (err.errorCode === 'RESOURCE_NOT_FOUND') {
                        platformCookie.remove(config.environment);
                    }
                    return reject(err);
                });
        });

    const toggleDevTools = () => {
        showDevTools.value = !showDevTools.value;
    };

    const setPlatformId = (value: Platform['id']) => {
        platformId.value = value;
        platformCookie.set(value, config.environment);
    };

    const setNumberFormat = (value: string) => {
        numberFormat.value = value;
        localStorage.setItem(NUMBER_FORMAT_KEY, value.toString());
    };

    const setIsEmbedded = (value: boolean) => {
        isEmbedded.value = value;
    };

    const setShowResourceIds = (value: boolean) => {
        showResourceIds.value = value;
        localStorage.setItem(SHOW_RESOURCE_IDS_KEY, value.toString());
    };

    const setShowNonBilledPricings = (value: boolean) => {
        showNonBilledPricings.value = value;
        localStorage.setItem(SHOW_NON_BILLED_PRICINGS_KEY, value.toString());
    };

    const toggleShowNonBilledPricings = () => {
        setShowNonBilledPricings(!showNonBilledPricings.value);
    };

    const setShowReferences = (value: boolean) => {
        showResourceReferences.value = value;
        localStorage.setItem(SHOW_RESOURCE_REFERENCE_KEY, value.toString());
    };

    const reset = () => {
        currencies.value = [];
        features.value = [];
        isEmbedded.value = false;
        showResourceIds.value = false;
        showResourceReferences.value = true;
        showNonBilledPricings.value = true;
        userInfo.value = undefined;
        showDevTools.value = false;
        platform.value = undefined;
        platformId.value = undefined;
    };

    return {
        features,
        isEmbedded,
        setIsEmbedded,
        platformId,
        setPlatformId,
        getAccountByPlatformId,
        getAccountGroupByPlatformId,
        showDevTools,
        toggleDevTools,
        showResourceIds,
        showResourceReferences,
        setShowReferences,
        setShowResourceIds,
        showNonBilledPricings,
        setShowNonBilledPricings,
        toggleShowNonBilledPricings,
        platform,
        loadPlatform,
        currencies,
        entitlements,
        permissions,
        loadCurrencies,
        loadEntitlements,
        userInfo,
        loadUserInfo,
        reset,
        numberFormat,
        setNumberFormat,
        hasPlatformDefaultSubscriptionSettings,
    };
};
