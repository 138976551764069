<template>
    <Transition
        enter-active-class="duration-300 ease-out transform-gpu"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="duration-300 ease-in transform-gpu"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
    >
        <slot />
    </Transition>
</template>
