import type { Ref } from 'vue';

/**
 * Utility to select all text in an element.
 */
export default () => {
    const select = (elementRef: Ref<HTMLElement>['value']) => {
        const range = document.createRange();
        range.selectNode(elementRef);

        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);
    };

    const unselect = () => {
        window.getSelection()?.removeAllRanges();
    };

    return {
        select,
        unselect,
    };
};
