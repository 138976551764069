import {
    createRouter,
    createWebHistory,
    type RouteLocationNormalized,
    type RouteLocationRaw,
} from 'vue-router';
import { isEqual } from 'lodash-es';
import type { Platform, Environment, UserInfoExpanded } from '@solvimon/types';
import { routes } from './routes';
import {
    applyGlobalRouteParams,
    isRouteAccessible,
    isRoutePlatformSpecific,
    PLATFORM_ID_PARAM,
} from '@/utils/router';
import { useApplicationStorage, useCustomFields, useApp, usePlatformCookie } from '@/composables';
import hasAccessToken from '@/utils/hasAccessToken';
import config from '@/config';
import { getAccessTokenParsed, setAccessToken } from '@/utils/accessToken';
import useAccountSwitcher from '@/components/AccountSwitcher/composables/useAccountSwitcher';
import { getValidPlatform } from '@/utils/platform';
import { getFallbackUserInfoPlatformId } from '@/services/identity';
import { trackSentryException } from '@/utils/errorTracking';

const applicationStorage = useApplicationStorage();

/**
 * The first word of a named route should always be the section. E.g 'meter'.
 * For instance 'meter-detail', helps us identify this as page as part of the 'meter' section.
 *  */

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: applyGlobalRouteParams(routes),
});

const handleAccess = async ({
    to,
}: {
    to: RouteLocationNormalized;
}): Promise<RouteLocationRaw | void> => {
    if (to.meta.public && to.name !== 'login') {
        return;
    }

    const hasAccess = await hasAccessToken();

    if (!hasAccess && to.name === 'login') {
        return;
    } else if (hasAccess) {
        // When the to route equals the login page, send to overview page.
        if (to.name === 'login') {
            return { name: 'overview' };
        }

        const { platformId, setPlatformId } = useApp();

        if (!platformId) {
            const jwt = getAccessTokenParsed();

            if (jwt?.platform_id) {
                setPlatformId(jwt.platform_id);
            }
        }

        // redirect the user to the login page when token validation and refresh failed
    } else {
        return { name: 'login' };
    }
};

/**
 * Store the previous route so we can check against it in the application.
 * Never store a reference to login or logout page.
 */
const storeCurrentRoute = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (isEqual(to, from)) return;

    if (to.name && !['login', 'logout', 'login-callback'].includes(to.name.toString())) {
        applicationStorage.set('currentPage', to.path);
    }
};

/**
 * Whenever the platform ID is set as a route parameter, we need to set it in
 * the application storage and refresh desk to make sure we get the correct data
 * for the newly selected platform.
 */
export const handleAuthParams = ({
    to,
}: {
    to: RouteLocationNormalized;
}): RouteLocationRaw | void => {
    const app = useApp();
    const platformCookie = usePlatformCookie();

    const platformId = to.params[PLATFORM_ID_PARAM] || platformCookie.get(config.environment);
    const { access_token, ...restQuery } = to.query;

    if (!platformId && !access_token) return;

    if (platformId) {
        const parsedPlatformId = platformId.toString();
        app.setPlatformId(parsedPlatformId);
    }

    if (access_token) {
        const token = access_token.toString();
        setAccessToken(token);

        return { ...to, query: restQuery };
    }
};

/**
 * Store the previous route so we can check against it in the application.
 * Never store a reference to login or logout page.
 */
const storePreviousRoute = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (isEqual(to, from)) return;

    if (
        to.name !== 'logout' &&
        from.name &&
        !['login', 'logout', 'login-callback'].includes(from.name.toString())
    ) {
        applicationStorage.set('previousPage', from.path);
    }
};

/**
 * This callback will take care of fetching application wide (global) data needed
 * by desk to function properly.
 */
const handleFirstPageLoad = async ({
    accountGroups,
    platformId,
}: {
    accountGroups: UserInfoExpanded['account_groups'];
    platformId: Platform['id'];
}): Promise<void> => {
    const app = useApp();

    if (!accountGroups) {
        await app.loadUserInfo(platformId);
    }

    await Promise.all([
        app.loadPlatform(platformId),
        app.loadCurrencies(platformId),
        app.loadEntitlements(platformId),
        useCustomFields().get(),
    ]);
};

const handleValidatePlatform = async (): Promise<{
    environment?: Environment;
    platformId?: Platform['id'];
    accountGroups: UserInfoExpanded['account_groups'];
}> => {
    try {
        const app = useApp();
        const accountGroups = (app.userInfo.value ?? (await app.loadUserInfo()))?.account_groups;

        if (!accountGroups || accountGroups.length === 0) {
            return { environment: undefined, platformId: undefined, accountGroups };
        }

        const platform = getValidPlatform({ accountGroups });

        if (platform.platformId !== getFallbackUserInfoPlatformId()) {
            await app.loadUserInfo(platform.platformId);
        }

        return {
            platformId: platform.platformId,
            environment: platform.environment,
            accountGroups,
        };
    } catch (error) {
        return { environment: undefined, platformId: undefined, accountGroups: [] };
    }
};

router.beforeEach(async (to, from) => {
    storeCurrentRoute(to, from);
    storePreviousRoute(to, from);

    const routeWithoutAuthParams = handleAuthParams({ to });

    /**
     * Refresh desk to make sure we get the correct data
     * for the newly selected platform.
     */
    if (routeWithoutAuthParams) {
        router.replace(routeWithoutAuthParams).then(() => {
            router.go(0);
        });

        return false;
    }

    /**
     * Check if the user is authenticated. If not, redirect user to login page.
     */
    const loginRedirect = await handleAccess({ to });
    if (loginRedirect) {
        return loginRedirect;
    }

    if (to.name && !to.meta.public) {
        const { platformId, environment, accountGroups } = await handleValidatePlatform();
        const app = useApp();

        if (!environment || !platformId) {
            return { name: 'logout' };
        }

        if (environment !== config.environment) {
            useAccountSwitcher().switchPlatform(platformId, environment);
            return false;
        }

        if (platformId !== app.platformId.value) {
            app.setPlatformId(platformId);
        }

        const platformIdFromRoute = to.params?.[PLATFORM_ID_PARAM];

        if (!platformIdFromRoute && isRoutePlatformSpecific(to)) {
            return {
                ...to,
                params: {
                    ...to.params,
                    platformId,
                },
            };
        }

        /**
         * When the platformId from to route is not the one returned by handleValidatePlatform,
         * you don't have access to it. So we redirect to the overview, to prevent loading the page of an unauthorized platform.
         */
        if (platformIdFromRoute && platformIdFromRoute !== platformId) {
            return { name: 'overview' };
        }

        /**
         * When the user is successfully authenticated, we need to fetch application wide data
         * before we can proceed loading desk.
         */
        if (!from.name || ['login', 'login-callback'].includes(from.name.toString())) {
            await handleFirstPageLoad({ platformId, accountGroups });
        }

        /**
         * When a route requires certain privileges, check if they're set in the userInfo.
         * Otherwise redirect to the restricted-access page.
         */
        if (!isRouteAccessible(to, app.userInfo.value?.privileges ?? [])) {
            trackSentryException(
                new Error(`Restricted access page. From: ${from.fullPath}; To: ${to.fullPath}`),
                {
                    from,
                    to,
                    'required privileges': to.meta.requiredPrivileges,
                    "user's privileges": app.userInfo.value?.privileges,
                }
            );
            return { name: 'restricted-access' };
        }
    }
});

export default router;
