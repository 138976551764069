import { createIntl as _createIntl, type IntlFormatters, type IntlShape } from '@formatjs/intl';
import type { App, Plugin } from 'vue';
import { intlKey } from 'vue-intl';
import messages from '../../locales/en-US.json';
import { numberFormatSetting } from '@/composables/useLocalStorageData';

const _intl = _createIntl({
    locale: numberFormatSetting ?? window.navigator.language,
    defaultLocale: 'en-US',
    messages,
    onError: (error) => {
        // Suppress missing translation errors since these will be extracted during build
        if (error.code === 'MISSING_TRANSLATION') {
            return;
        }

        // eslint-disable-next-line no-console
        console.error(error);
    },
});

/**
 * This is a copy of the createIntl function from `vue-intl`, with a few changes.
 * Because it is currently not natively possible to use the intl object outside
 * of vue components, we're overriding the default `createIntl` function.
 *
 * The original function can be found here:
 * https://github.com/formatjs/formatjs/blob/main/packages/vue-intl/plugin.ts
 *
 */
// TODO: Update when `vue-intl` exposes raw intl object.
const createIntl = (intl: IntlShape): Plugin => ({
    install(app: App) {
        app.config.globalProperties.$intl = intl;
        app.config.globalProperties.$t = intl.formatMessage;
        app.config.globalProperties.$d = intl.formatDate;
        app.config.globalProperties.$tm = intl.formatTime;
        app.config.globalProperties.$dtr = intl.formatDateTimeRange;
        app.config.globalProperties.$rt = intl.formatRelativeTime;
        app.config.globalProperties.$dn = intl.formatDisplayName;
        app.config.globalProperties.$n = intl.formatNumber;
        app.config.globalProperties.$l = intl.formatList;

        app.provide(intlKey, intl);
    },
});

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $intl: IntlShape;
        $t: IntlFormatters['formatMessage'];
        $d: IntlFormatters['formatDate'];
        $tm: IntlFormatters['formatTime'];
        $dtr: IntlFormatters['formatDateTimeRange'];
        $rt: IntlFormatters['formatRelativeTime'];
        $dn: IntlFormatters['formatDisplayName'];
        $n: IntlFormatters['formatNumber'];
        $l: IntlFormatters['formatList'];
    }
}

/**
 * The intl object to be used by the vue app.
 */
export const intlPlugin = createIntl(_intl);

/**
 * Raw intl object, only to be used outside of vue components.
 */
export const useRawIntl = () => _intl;
