import { setAccessToken } from './accessToken';
import { getRefreshedAccessToken } from '@/services/identity';

export default async (): Promise<boolean> =>
    new Promise((resolve) => {
        getRefreshedAccessToken()
            .then((result) => {
                if (!result.access_token || !result.expires_in) {
                    resolve(false);
                    return;
                }

                setAccessToken(result.access_token, result.expires_in);
                resolve(true);
            })
            .catch(() => {
                resolve(false);
            });
    });
