import type {
    PricingPlanVersion,
    PricingPlan,
    PricingPlanVersionExtended,
    PricingCategory,
    UnsavedPricingCategory,
    UpdatedPricingCategory,
} from '@solvimon/types';
import { handleResponse } from './lib';
import type { PricingPlanVersionPayload } from './pricingPlanVersions.types';
import { del, get, patch, post } from '@/callcenter';
import { serializeQueryParams, withExpand } from '@/utils/api';
import type { GetOptions } from '@/callcenter/get.types';

const ENDPOINT = `${import.meta.env.CONFIG_URL}/pricing-plan-versions`;

export const createPricingPlanVersion = (
    pricingPlanId: PricingPlan['id'],
    data: Omit<PricingPlanVersionPayload, 'pricing_plan_id'>
) => {
    return handleResponse({
        request: post(ENDPOINT, {
            ...data,
            pricing_plan_id: pricingPlanId,
        }),
    });
};

interface UpdatedPricingPlanVersion extends Omit<PricingPlanVersion, 'pricing_categories'> {
    pricing_categories: (PricingCategory | UnsavedPricingCategory | UpdatedPricingCategory)[];
}

/**
 * Update a pricing plan version.
 */
export const updatePricingPlanVersion = (
    id: PricingPlanVersion['id'],
    data: Partial<UpdatedPricingPlanVersion>,
    options?: Parameters<typeof patch>[2]
) => {
    return handleResponse<PricingPlanVersion>({
        request: patch(`${ENDPOINT}/${id}`, data, options),
    });
};

export const deletePricingPlanVersion = (id: PricingPlanVersion['id']) => {
    return handleResponse({
        request: del(`${ENDPOINT}/${id}`),
    });
};

export const getPricingPlanVersion = (id: PricingPlanVersion['id'], options?: GetOptions) => {
    return handleResponse<PricingPlanVersion>({
        request: get(`${ENDPOINT}/${id}`, options),
    });
};

// Reusable properties so we don't forget expand properties on other resources
export const EXPANDED_PRICING_PLAN_VERSION_PROPERTIES = [
    'pricing_categories.product_category_id',
    'pricing_categories.pricings.product_ids',
    'pricing_categories.pricings.entitlements.feature_id',
    'pricing_categories.pricings.items.configs.meter_properties.id',
    'pricing_categories.pricings.items.product_item_ids',
    'pricing_categories.pricings.items.product_items.meter_value_calculation_id',
    'pricing_categories.pricings.items.product_items.meter_value_calculation.meter_value_id',
    'pricing_categories.pricings.items.product_items.meter_value_calculation.meter_id',
    'pricing_categories.pricings.items.product_items.meter_value_calculation.meter.meter_values',
    'pricing_categories.pricings.items.product_items.meter_value_calculation.meter.meter_values.id',
    'pricing_categories.pricings.items.product_items.meter_value_calculation.meter.meter_properties',
    'pricing_categories.pricings.items.product_items.meter_value_calculation.meter.meter_properties.id',
    'pricing_categories.pricing_groups.pricings.product_ids',
    'pricing_categories.pricing_groups.pricings.entitlements.feature_id',
    'pricing_categories.pricing_groups.pricings.items.configs.meter_properties.id',
    'pricing_categories.pricing_groups.pricings.items.product_item_ids',
    'pricing_categories.pricing_groups.pricings.items.product_items.meter_value_calculation_id',
    'pricing_categories.pricing_groups.pricings.items.product_items.meter_value_calculation.meter_value_id',
    'pricing_categories.pricing_groups.pricings.items.product_items.meter_value_calculation.meter_id',
    'pricing_categories.pricing_groups.pricings.items.product_items.meter_value_calculation.meter.meter_values',
    'pricing_categories.pricing_groups.pricings.items.product_items.meter_value_calculation.meter.meter_values.id',
    'pricing_categories.pricing_groups.pricings.items.product_items.meter_value_calculation.meter.meter_properties',
    'pricing_categories.pricing_groups.pricings.items.product_items.meter_value_calculation.meter.meter_properties.id',
];

export const getPricingPlanVersionExtended = (
    id: PricingPlanVersion['id'],
    options?: GetOptions
) => {
    const queryParams = withExpand({
        expandParams: ['pricing_plan_id', ...EXPANDED_PRICING_PLAN_VERSION_PROPERTIES],
    });

    return handleResponse<PricingPlanVersionExtended>({
        request: get(`${ENDPOINT}/${id}${serializeQueryParams(queryParams)}`, options),
    });
};
