import type { Account, AccountGroupExpanded, Environment } from '@solvimon/types';

const getAccounts = (accountGroups: AccountGroupExpanded[]) =>
    accountGroups
        .map((accountGroup) => [
            ...accountGroup.account_memberships.map(
                (accountMembership) => accountMembership.account
            ),
        ])
        .flat();

export const getAccountById = ({
    accountGroups,
    id,
}: {
    accountGroups: AccountGroupExpanded[];
    id: Account['id'];
}) => {
    const accounts = getAccounts(accountGroups);

    return accounts.find((account) => account.id === id);
};

export const getFirstAccountForEnvironment = ({
    accountGroups,
    environment,
}: {
    accountGroups: AccountGroupExpanded[];
    environment: Environment;
}): Account | undefined => {
    const accounts = getAccounts(accountGroups);

    const account = accounts.find((account) => account.environment === environment);

    return account || undefined;
};

export const getFirstAccount = ({
    accountGroups,
}: {
    accountGroups: AccountGroupExpanded[];
}): Account | undefined => {
    const accounts = getAccounts(accountGroups);

    return accounts ? accounts[0] : undefined;
};
