import { getDefaultHeaders, getErrorTrackingParams, handleError, isApiErrorResponse } from './lib';
import type { ApiErrorResponse } from './types';
import hasAccessToken from '@/utils/hasAccessToken';
import router from '@/router';
import { Headers } from '@/constants';
import { trackSentryException } from '@/utils/errorTracking';

export default async (
    endpoint: string,
    data: object = {},
    options?: { showToastOnError: boolean }
) => {
    if (await hasAccessToken()) {
        return await fetch(endpoint, {
            method: 'PATCH',
            headers: getDefaultHeaders(),
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then(async (response) => {
                const json = await response.json().catch((error) => {
                    // Log to console, to make debugging easier. Also for back-end devs
                    // eslint-disable-next-line no-console
                    console.error(error);
                    trackSentryException(error, { Message: 'Failed parsing JSON from api' });

                    throw {
                        hasError: true,
                        statusCode: response.status,
                        requestId: response.headers.get(Headers.X_REQUEST_ID),
                    };
                });

                if (!response.ok) {
                    throw {
                        hasError: true,
                        statusCode: response.status,
                        message: json?.message,
                        requestId: response.headers.get(Headers.X_REQUEST_ID),
                        field: json?.field,
                    };
                }

                return json;
            })
            .catch(async (error: ApiErrorResponse | unknown) => {
                trackSentryException(
                    isApiErrorResponse(error)
                        ? new Error(`PATCH: ${endpoint} - ${error.message}`)
                        : new Error(`PATCH: ${endpoint}`),
                    {
                        Payload: data,
                        Error: error,
                        ...getErrorTrackingParams(error),
                    }
                );

                await handleError(
                    {
                        message: isApiErrorResponse(error) ? error.message : '',
                        statusCode: isApiErrorResponse(error) ? error.statusCode : undefined,
                        requestId: isApiErrorResponse(error) ? error.requestId : undefined,
                    },
                    options
                );

                return Promise.reject(error);
            });
    }
    // if no valid token was found and refreshing the token failed: require a new login
    router.push({ name: 'login' });
};
