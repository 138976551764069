<script setup lang="ts">
import { Toast } from '@solvimon/ui';
import { ref } from 'vue';
import type { ToastProps } from './Toast.types';
import { animationClass, progressBarColor } from './Toast.config';
import { useNotifications } from '@/composables';

const hovering = ref(false);

withDefaults(
    defineProps<{
        type: ToastProps['type'];
        message: ToastProps['message'];
        cta?: ToastProps['cta'];
        persistent?: boolean;
        autoCloseDelay?: ToastProps['autoCloseDelay'];
    }>(),
    {
        persistent: false,
        autoCloseDelay: 'default',
    }
);

const { queue, getItemKey } = useNotifications();
</script>

<template>
    <Toast
        v-bind="$attrs"
        :type="type"
        closable
        class="relative"
        @close="queue.pop()"
        @mouseenter="hovering = true"
        @mouseleave="hovering = false"
    >
        <div>{{ message }}</div>
        <RouterLink
            v-if="cta"
            :to="cta.to"
            class="font-medium hover:underline"
            @click="queue.pop()"
        >
            {{ cta.text }}
        </RouterLink>
        <div
            v-if="!persistent"
            :class="progressBarColor[type].base"
            class="absolute left-0 bottom-0 right-0 h-1"
        >
            <div
                :class="[
                    animationClass[autoCloseDelay],
                    progressBarColor[type].bar,
                    { pause: hovering },
                ]"
                class="h-1 w-full"
                @animationend="
                    () => {
                        queue = queue.filter(
                            (item) => getItemKey({ type, message, cta }) !== getItemKey(item)
                        );
                    }
                "
            />
        </div>
    </Toast>
</template>
