import type {
    RouteLocationNormalized,
    RouteRecordRaw,
    RouteRecordSingleViewWithChildren,
    RouteLocationResolvedGeneric,
} from 'vue-router';
import type { Privilege } from '@solvimon/types';
import { getResourceIdRegex } from '@/utils/resources';

export const PLATFORM_ID_PARAM = 'platformId';
const PLATFORM_ID_REGEX = getResourceIdRegex('plat');
const PLATFORM_ID_ROUTE_PATH_PREFIX = `/:${PLATFORM_ID_PARAM}(${PLATFORM_ID_REGEX})?`;

export const applyGlobalRouteParams = (routes: RouteRecordRaw[]) => {
    return routes.map(applyGlobalRouteParamsToRoute);
};

export const applyGlobalRouteParamsToRoute = <
    T extends RouteRecordRaw | RouteRecordSingleViewWithChildren
>(
    route: T
): T => {
    const children = route.children?.map(applyGlobalRouteParamsToRoute);

    return {
        ...route,
        path: isRoutePlatformSpecific(route)
            ? `${PLATFORM_ID_ROUTE_PATH_PREFIX}${route.path}`
            : route.path,
        ...(children ? { children } : {}),
    };
};

export const removeGlobalRouteParamsFromRoutePath = (
    routePath: RouteRecordRaw['path']
): RouteRecordRaw['path'] => {
    return routePath.replace(new RegExp(`/${PLATFORM_ID_REGEX}`), '');
};

export const removeGlobalRouteParamsFromRoute = <
    T extends RouteRecordRaw | RouteRecordSingleViewWithChildren
>(
    route: T
): T => {
    const children = route.children?.map(removeGlobalRouteParamsFromRoute);

    return {
        ...route,
        path: isRoutePlatformSpecific(route)
            ? route.path.replace(PLATFORM_ID_ROUTE_PATH_PREFIX, '')
            : route.path,
        ...(children ? { children } : {}),
    };
};

export const isRoutePlatformSpecific = (route: RouteRecordRaw | RouteLocationNormalized) => {
    // to.meta.platformSpecific is omitted by default
    return route.meta?.platformSpecific !== false;
};

export const isRouteAccessible = (
    route: RouteLocationNormalized | RouteLocationResolvedGeneric,
    userPrivileges: Privilege[]
) => {
    return (
        !route.meta.requiredPrivileges ||
        route.meta.requiredPrivileges.every((requiredPrivilege) => {
            return userPrivileges.some(({ privilege }) => privilege === requiredPrivilege);
        })
    );
};
