import type { Environment } from '@solvimon/types';
import * as packageJson from '../../package.json';
import config from '@/config';
import type { Platform } from '@/services/platforms.types';

/**
 *
 */
export default () => {
    const redirectToEnvironment = (
        url: string,
        environment: Environment,
        platformId: Platform['id']
    ) => {
        window.location.assign(`${environmentToHostMap[environment]}/${platformId}/overview`);
    };

    return { redirectToEnvironment };
};

const environmentToHostMap: Record<Environment, string> = {
    DEV: `https://${config.host.DEV}:${packageJson.config.local.port}`,
    BETA: `https://${config.host.BETA}`,
    TEST: `https://${config.host.TEST}`,
    LIVE: `https://${config.host.LIVE}`,
};
